import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { TfiClose } from "react-icons/tfi";
import { authenticationService } from "../../service/authentication";
import CustomToast from "../toaster";

interface AlertModalProps {
  show: boolean;
  onHide: () => void;
  title: string;
  message: string;
  onConfirm: () => void;
}

const AlertModal: React.FC<AlertModalProps> = ({
  show,
  onHide,
  title,
  message,
  onConfirm,
}) => {

  const handleConfirm = (event: any) => {
    const button = event.target; // Get the button element
    button.disabled = true; // Disable the button
    onConfirm(); // Call the onConfirm function
  };

  return (
    <Modal centered size="sm" show={show}>
      <div>
        <div className="modalHead">
          <p className="modalHeadText2 mx-3 my-2">{title}</p>
          <div onClick={onHide} className="p-2 mx-2">
            <TfiClose fill="#FFFFFF" />
          </div>
        </div>
        <div className="text-center mt-3 mb-5">
          <p className="normalText2 ">
            Are you sure you want to <br />
            <span>
              <strong>{message}</strong>
            </span>
          </p>
        </div>
        <div className="d-flex justify-content-around pb-3">
          <button onClick={onHide} className="modalCancelBtn2">
            Cancel
          </button>
          <button
            onClick={(e) => handleConfirm(e)}
            className="squareOrangeBtn3"
          >
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
};

interface eSignModalProps {
  show: boolean;
  onHide: () => void;
  onConfirm: (remark: string) => void;
  needRemraks: boolean;
}

interface Errors {
  esign?: string;
  remark?: string;
}

export const EsignModal: React.FC<eSignModalProps> = ({
  show,
  onHide,
  onConfirm,
  needRemraks,
}) => {
  const [errors, setErrors] = useState<Errors>({});
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const defaultFormData = {
    esign: "",
    remark: "",
  };
  const [formData, setFormData] = useState(defaultFormData);

  const handleAddFieldOnChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const checkSpecialChar = (e: any) => {
    if (!/[0-9a-zA-Z]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleConfirm = (event: any) => {
    const errors: Errors = {};

    if (!formData.esign) {
      errors.esign = "Please enter Password";
    }
    if (!formData.remark && needRemraks) {
      errors.remark = "Please enter Remarks";
    }
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    // const button = event.target; // Get the button element
    // button.disabled = true;
    setErrors({})
    EsignValidationProcess();
  };

  const EsignValidationProcess = async () => {
    const dataToSend = {
      password: formData.esign,
    };

    try {
      const response = await authenticationService.validateEsign(dataToSend);
      if (response.status && response.statusCode === 200) {
        onConfirm(formData.remark);
        setToastType("success");
        setToastMessage(response.message);
        setFormData(defaultFormData);
      }else{
        setToastType("error");
        setToastMessage(response.message);
      }
      setShowToast(true);
    } catch (error) {
      console.error("Plate response", error);
    }
   
  };

  const handleClose = () => {
    onHide();
    setFormData(defaultFormData);
    setErrors({})
  };

  return (
    <>
    <CustomToast
    show={showToast}
    onClose={() => setShowToast(false)}
    delay={5000}
    message={toastMessage}
    toastType={toastType}
    />

    <Modal centered aria-labelledby="contained-modal-title-vcenter" show={show}>
      <div>
        <div className="modalHead">
          <p className="modalHeadText2 mx-3 my-2">E-Sign</p>
          <div onClick={handleClose} className="p-2 mx-2">
            <TfiClose fill="#FFFFFF" />
          </div>
        </div>
        <div className="mt-3">        
          {needRemraks && (
            <p className="normalText2 ">
            Please provide Password and Remarks to continue <br />
            </p>
          )}
          {!needRemraks && (
            <p className="normalText2 ">
            Please provide Password to continue <br />
            </p>
          )}        
        </div>
        <div className="selectWrap-new mb-2">
          <form autoComplete="off" className="form-group my-1 mx-3 px-2">
            <label>
              Password
              <span style={{ color: "red" }}>*</span>
            </label>
            <br />
            <input
              type="password"
              name="esign"
              id="esign"
              value={formData.esign}
              onChange={handleAddFieldOnChange}
            />
            {errors.esign && <p className="errorText">{errors.esign}</p>}
          </form>

        {needRemraks &&(
          <div className="form-group my-1 mx-3 px-2">
            <label>
              Remarks
              <span style={{ color: "red" }}>*</span>
            </label>
            <br />
            <input
              type="text"
              name="remark"
              id="remark"
              value={formData.remark}
              onChange={handleAddFieldOnChange}
              onKeyDown={(e) => checkSpecialChar(e)}
            />
            {errors.remark && <p className="errorText">{errors.remark}</p>}
          </div>
          )}

        </div>
        <div
          className="d-flex justify-content-end"
          style={{ fontSize: "small" }}
        >
          <button onClick={handleClose} className="modalCancelBtn2 m-3">
            Cancel
          </button>
          <button
            onClick={(e) => handleConfirm(e)}
            className="squareOrangeBtn3 m-3"
          >
            Confirm
          </button> 
        </div>
      </div>
    </Modal>
   </>
  );
};

export default AlertModal;
