export const columns: TableColumn[] = [
  {
    Header: "tableCommon.sl_no",
    accessor: "slno",
  },
  {
    Header: "scheduleProduction.tableColumns.production_schedule_name",
    accessor: "production_schedule_name",
  },
  {
    Header: "scheduleProduction.tableColumns.product_name",
    accessor: "product_name",
  },
  {
    Header: "scheduleFrequency.tableColumns.productionType",
    accessor: "production_type",
  },
  {
    Header: "scheduleProduction.tableColumns.area_name",
    accessor: "area_name",
  },
  {
    Header: "scheduleProduction.tableColumns.from_date",
    accessor: "from_date_time",
  },
  {
    Header: "scheduleProduction.tableColumns.to_date",
    accessor: "to_date_time",
  },
];

export const opColumns: TableColumn[] = [
  {
    Header: "tableCommon.sl_no",
    accessor: "slno",
  },
  {
    Header: "scheduleProduction.tableColumns.operator_name",
    accessor: "operator_name",
  },
  {
    Header: "scheduleOperator.tableColumns.start_date_time",
    accessor: "start_date_time",
  },
  {
    Header: "scheduleOperator.tableColumns.end_date_time",
    accessor: "end_date_time",
  },
  {
    Header: "scheduleOperator.work_shift",
    accessor: "work_shift_name",
  },
  {
    Header: "scheduleOperator.tableColumns.location_name",
    accessor: "location_name",
  },
  {
    Header: "scheduleOperator.tableColumns.activity_type_name",
    accessor: "activity_type_name",
  },
  {
    Header: "scheduleProduction.tableColumns.plateCount",
    accessor: "plateCount",
  },
];

export const activityFrequencyColumns: TableColumn[] = [
  {
    Header: "scheduleProduction.tableColumns.activityType",
    accessor: "name",
  },
  {
    Header: "scheduleProduction.tableColumns.location_name",
    accessor: "location_name",
  },
  // {
  //   Header: "scheduleProduction.tableColumns.workflow",
  //   accessor: "activity_workflow_definition_name",
  // },
  {
    Header: "scheduleProduction.tableColumns.critical_location_frequency",
    accessor: "critical_frequency",
  },
  {
    Header: "scheduleProduction.tableColumns.noncritical_frequency",
    accessor: "noncritical_frequency",
  },
];

interface TableColumn {
  Header: string;
  accessor: string;
}
