import React, { useState } from "react";
import HeaderSection from "../../../common/headerSection";
import PageHeader from "../../../common/pageHeader";
import { Link, useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../../../store/store";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const SubNavigationComponent = () => {
  const navigate = useNavigate();
  // const areaId = useSelector((state: RootState) => state.graphType.GraphType);
  const [selectedTab, setSelectedTab] = useState("2");
  const { t } = useTranslation();

  const { areaId } = useParams();


  const handleTabClick = (tabName : any) => {
    setSelectedTab(tabName);
  };

  const options = [
    {
      label: t("common.by_area"),
      description: t("analyticalDashboard.about_by_area"),
      defaultValue:'area'
    },
    {
      label: t("common.by_location"),
      description: t("analyticalDashboard.about_by_location"),
      defaultValue:'location'
    },
    {
      label: t("common.by_operator"),
      description: t("analyticalDashboard.about_by_operator"),
      defaultValue:'operator'
    },
    {
      label: t("common.daily"),
      description: t("analyticalDashboard.about_daily"),
      defaultValue:'day',
    },
    
    {
      label: t("common.weekly"),
      description: t("analyticalDashboard.about_weekly"),
      defaultValue:'week'
    },
    {
      label: t("common.monthly"),
      description: t("analyticalDashboard.about_monthly"),
      defaultValue:'month'
    },
    {
      label: t("common.quarterly"),
      description: t("analyticalDashboard.about_quarterly"),
      defaultValue:'quarter'
    },
    {
      label: t("common.yearly"),
      description: t("analyticalDashboard.about_yearly"),
      defaultValue:'year'
    },
  ];
  const optionsEscursion = [
    { label: t("common.daily"), description: "" , defaultValue:'day'},
    { label: t("common.weekly"), description: "" , defaultValue:'week'},
    { label: t("common.monthly"), description: "" , defaultValue:'month'},
    { label: t("common.quarterly"), description: "", defaultValue:'quarter' },
    { label: t("common.yearly"), description: "" , defaultValue:'year'},
  ];

  const optionsSummary = [
    { label: t("common.by_area"), description: "" , defaultValue:'area'},
    { label: t("common.by_location"), description: "" , defaultValue:'location'},
    { label: t("common.by_operator"), description: "" , defaultValue:'operator'},
    { label: t("dashboard.schedule_summary"), description: "" , defaultValue:'schedule'},
    // { label: t("dashboard.discrepency_summary"), description: "" , defaultValue:'discrepancy'},
    { label: t("dashboard.exception_summary"), description: "" , defaultValue:'exception'},
    { label: t("dashboard.active_sampling"), description: "" , defaultValue:'active_sampling'},
    { label: t("dashboard.passive_sampling"), description: "" , defaultValue:'passive_sampling'},
    { label: t("dashboard.monitoring_report"), description: "" , defaultValue:'monitoring_report'},
    { label: t("dashboard.surface_monitoring"), description: "" , defaultValue:'surface_monitoring'},
    { label: t("dashboard.surface_monitoring_report"), description: "" , defaultValue:'surface_monitoring_report'},
    { label: t("dashboard.configuration_report"), description: "" , defaultValue:'configuration_report'},
    { label: t("dashboard.inspection"), description: "" , defaultValue:'inspection'},
    { label: t("dashboard.incident_deviation"), description: "" , defaultValue:'incident_deviation'},
    { label: t("dashboard.plate_report"), description: "" , defaultValue:'plate_report'}, 
    { label: t("dashboard.batch_summary_report"), description: "" , defaultValue:'batch_summary_report'}, 
  ];

  const optionsViolation = [
    { label: t("common.by_area"), description: "" , defaultValue:'area'},
    { label: t("common.by_location"), description: "" , defaultValue:'location'},
    { label: t("common.by_operator"), description: "" , defaultValue:'operator'},
    { label: t("common.daily"), description: "" , defaultValue:'day'},
    { label: t("common.weekly"), description: "" , defaultValue:'week'},
    { label: t("common.monthly"), description: "" , defaultValue:'month'},
    { label: t("common.quarterly"), description: "", defaultValue:'quarter' },
    { label: t("common.yearly"), description: "" , defaultValue:'year'},
  ];

  interface Option {
    label: string;
    description: string;
    defaultValue: string;
  }
  
  const renderOptions = () => {
    let optionsToRender: Option[] = []; 
    if (areaId === "Trends") {
      optionsToRender = options;
    } else if (areaId === "Excursion") {
      optionsToRender = optionsEscursion;
    } else if (areaId === "Summary") {
      optionsToRender = optionsSummary;
    } else if (areaId === "Violations") {
      optionsToRender = optionsViolation;
    } else if (areaId === ``) {
      navigate("/analytics-dashboard");
    } 
  
    return optionsToRender.map((option: Option, index: number) => (
      <div key={index} className="col-6">
        <button
          className={`optionSelect`}
          onClick={() => handleOptionChange(option.defaultValue.split(' | ')[0])}
          title={option.defaultValue.split(' | ')[1]}
        >
          <div className="option-content">
            <span className="option-label">{option.label}</span>
            <span className="option-description">{option.description}</span>
          </div>
        </button>
      </div>
    ));
  };
  

  const handleOptionChange = (bulletId: string) => {
    if (areaId == "Trends") {
      navigate(`/trend-analytics/${bulletId}`);
    }
    if (areaId == "Excursion") {
      navigate(`/excursion-report/${bulletId}`);
    }
    if (areaId == "Summary") {
      navigate(`/summary-report/${bulletId}`);
    }
    // if (areaId == "Violations") {
    //   navigate(`/violation-home/${bulletId}/${selectedTab}`);
    // }

    if (areaId === "Violations") {
      let path = `/violation-home/${bulletId}`;
      
      if (selectedTab === "1") {
        path += '_schedule';
      } else if (selectedTab === "2") {
        path += '_exception';
      }
    
      navigate(`${path}/${selectedTab}`);
    }
  };

  return (
    <div>
      <HeaderSection />
       <PageHeader pageHeader={`${areaId} Dashboard`} />
      <nav aria-label="breadcrumb" className="breadcrumb-container row">
        <div className="col-10 d-flex">
          <ol className="breadcrumb">
            <li className="breadcrumb-item dashboard-hover">
              <Link to="/analytics-dashboard">{t('dashboard.analyticalDashboard')}</Link>
            </li>
            <li
              className="breadcrumb-item active selectedArea"
              aria-current="page"
            >
              {areaId} {t('dashboard.title')}
            </li>
          </ol>
        </div>
      </nav>
     {areaId === "Violations" && (
      <div className="violation-tabs">
        {/* <div className={`violation-tab ${selectedTab === "1" ? "active" : ""}`} onClick={() => handleTabClick("1")} style={{"marginLeft" : "10%"}}>
          <div className="tab-content">
            <div className="tab-name">{t('analyticalDashboard.schedule_violations')}</div>
            <div className="tab-description">{t('analyticalDashboard.about_schedule_violations')}</div>
          </div>
        </div> */}
        <div className={`violation-tab ${selectedTab === "2" ? "active" : ""}`} onClick={() => handleTabClick("2")} style={{"marginLeft" : "30%", "marginTop" : "15px"}}>
          <div className="tab-content">
            <div className="tab-name">{t('analyticalDashboard.exception_violation')}</div>
            <div className="tab-description">{t('analyticalDashboard.about_exception_violation')}</div>
          </div>
        </div>
        {/* <div className={`violation-tab ${selectedTab === "3" ? "active" : ""}`} onClick={() => handleTabClick("3")}>
          <div className="tab-content">
            <div className="tab-name">{t('analyticalDashboard.discrepancy_violations')}</div>
            <div className="tab-description">{t('analyticalDashboard.about_discrepancy_violations')}</div>
          </div>
        </div> */}
      </div>
    )}
      <div className="col-12 col-md-8 d-flex justify-content-center mt-5">
        <div className="row">{renderOptions()}</div>
      </div>
    </div>
  );
};

export default SubNavigationComponent;
