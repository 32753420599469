export const columns: TableColumn[] = [
    {
        Header: 'tableCommon.sl_no',
        accessor: 'slno',
      },
      {
        Header: 'Grade Type',
        accessor: 'grade',
      },
      {
        Header: 'scheduleFrequency.tableColumns.activity_type_name',
        accessor: 'activity_type',
      },
      {
        Header: 'Minimum CFU Count',
        accessor: 'min_count',
      },
      {
        Header: 'Maximum CFU Count',    
        accessor: 'max_count',
      },
]

interface TableColumn {
    Header: string;
    accessor: string;
  }