import axios from "axios";
import baseUrl from "..";
import { handleLogOut } from "../../utils/oathUtils";
let authToken = ""
let authUserID = ""
let authTokenValidator= ""
let authUserIDValidator= ""
const storedData = localStorage.getItem('userDetails');

if (storedData) {
  try {
    const storedObject = JSON.parse(storedData);
    if (storedObject && Array.isArray(storedObject) && storedObject.length > 0) {
      authToken = storedObject[0].x_auth_token
      authUserID = storedObject[0].x_userid
    }
  } catch (error) {
    console.error('Failed to parse user details:', error);
  }
}

const LoginData = localStorage.getItem('loginDetails');

if (LoginData) {
  try {
    const storedObject = JSON.parse(LoginData);
    if (storedObject && Array.isArray(storedObject) && storedObject.length > 0) {
      authTokenValidator = storedObject[0].x_auth_token
      authUserIDValidator = storedObject[0].x_userid
    }
  }catch (error) {
      console.error('Failed to parse user details:', error);
    }
  }




  // Create an Axios instance
  const axiosInstance = axios.create({
    baseURL: baseUrl
  });

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    response => {
      // If the response is successful, just return the response
      return response;
    },
    error => {
      // Check for 400 or 401 status codes
      if (axios.isAxiosError(error) && (error.response?.status === 408 || error.response?.status === 401)) {
        // Redirect to login page
        handleLogOut()
      }
      // Return any other errors
      return Promise.reject(error);
    }
  );

  const loginRequest = async (username: string, password: string,transferSession:boolean) => {
    try {
      const response = await axios.post(`${baseUrl}/empats/login`, {
        user_id: username,
        password: password,
        transferSession:transferSession
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  // const changePassword = async (
  //   username: string,
  //   password: string,
  //   new_password: string,
  //   confirm_password: string
  // ) => {
  //   try {
  //     const response = await axiosInstance.post(
  //       `${baseUrl}/empats/change_password`, 
  //       {
  //         user_id: username,
  //         password: password,
  //         new_password: new_password,
  //         confirm_password: confirm_password,
  //       }, 
  //       {
  //         headers: {
  //           'x-auth-token': authTokenValidator,
  //           'x-userid': authUserIDValidator
  //         }
  //       }
  //     );
  //     return response.data;
  //   } catch (error) {
    //   if (axios.isAxiosError(error)) {
    //     return error.response?.data
    //   }
    // }
  // };

  const changePassword = async (
    username: string,
    password: string,
    new_password: string,
    confirm_password: string
  ) => {
    let authTokenValidator = '';
    let authUserIDValidator = '';
  
    // Retrieve and parse login details from localStorage
    const loginData = localStorage.getItem('loginDetails');
    if (loginData) {
      try {
        const storedObject = JSON.parse(loginData);
        if (storedObject && Array.isArray(storedObject) && storedObject.length > 0) {
          authTokenValidator = storedObject[0].x_auth_token;
          authUserIDValidator = storedObject[0].x_userid;
        }
      } catch (error) {
        console.error('Failed to parse login details:', error);
      }
    }
  
    // Ensure the tokens are available
    if (!authTokenValidator || !authUserIDValidator) {
      console.error('Authentication details are missing');
      return { error: 'Authentication details are missing' };
    }
  
    try {
      const response = await axiosInstance.post(
        `${baseUrl}/empats/change_password`, 
        {
          user_id: username,
          password: password,
          new_password: new_password,
          confirm_password: confirm_password,
        }, 
        {
          headers: {
            'x-auth-token': authTokenValidator || authToken,
            'x-userid': authUserIDValidator || authUserID
          }
        }
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  };

  const ressetPassword = async (userId: string , interface_name:string) => {
    try {
      const response = await axiosInstance.post(`${baseUrl}/empats/force_reset_password`, {
        user_id: userId,
        interface_name: interface_name
      },{
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const listNotification = async (completeStatus:string, read_status:string, id: string) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/user_notification/list_notification?sort=id&order=desc&complete_status=${completeStatus}&read_status=${read_status}&status=active&user_id=${authUserID}`,{
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }

  const listNotificationNew = async () => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/user_notification/list_all_notification?sort=id&order=desc&complete_status=&status=active&user_id=${authUserID}&notification_type=`,{
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }

  const listNotificationCount = async (id: string) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/user_notification/list_notification?sort=id&order=desc&read_status=0&status=active&user_id=${id}`,{
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }

  const updateNotification = async (dataToSend:any) => {
    try {
      const response = await axiosInstance.post(`${baseUrl}/user_notification/update_user_notification`, dataToSend ,{
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      console.log(response, "updateNotification")
      return response.data;
    } catch (error) {
      return error;
    }
  }

  const updateNotificationRemark = async (dataToSend:any) => {
    try {
      const response = await axiosInstance.post(`${baseUrl}/user_notification/update_user_notification_remarks`, dataToSend ,{
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
  
  const LogoutApi = async (id: string,x_auth_token: string, x_user_id: string) => {
    try {
        const headers = {
          'x-auth-token': x_auth_token,
          'x-userid': x_user_id,
        };
        const response = await axios.post(`${baseUrl}/empats/logout`, {
            id: id,
        }, { headers });
        return response.data;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          return error.response?.data
        }
    }
}

    const SnoozeNotification = async (dataToSend:any) => {
      try {
        const response = await axiosInstance.post(`${baseUrl}/user_notification/snooze_notification`, dataToSend ,{
          headers: {
            'x-auth-token': authToken,
            'x-userid': authUserID
          }
        });
        return response.data;
      } catch (error) {
        return error;
      }
    }

    const listNotificationEvents = async () => {
      try {
        const response = await axiosInstance.get(`${baseUrl}/notification/list_notification_events?sort=id&order=desc`,{
          headers: {
            'x-auth-token': authToken,
            'x-userid': authUserID
          }
        });
        return response.data;
      } catch (error) {
        return error;
      }
    }

    const saveNotificationSettings = async (dataToSend:any) => {
      try {
        const response = await axiosInstance.post(`${baseUrl}/notification/save_notification_settings`, dataToSend ,{
          headers: {
            'x-auth-token': authToken,
            'x-userid': authUserID
          }
        });
        return response.data;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          return error.response?.data
        }
      }
    }

    const validateEsign = async (dataToSend :any) => {
      try {
        const response = await axiosInstance.post(`${baseUrl}/validate/user_logged_in_session`, dataToSend ,
        {
          headers: {
            'x-auth-token': authToken,
            'x-userid': authUserID
          }
        });
        return response.data;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          return error.response?.data
        }
      }
    }

  const authenticationService = {
    loginRequest,
    changePassword,
    listNotification,
    updateNotification,
    ressetPassword,
    LogoutApi,
    listNotificationCount,
    SnoozeNotification,
    updateNotificationRemark,
    listNotificationNew,
    listNotificationEvents,
    saveNotificationSettings,
    validateEsign
  };
  
  export { authenticationService };