import React, { useEffect, useState } from "react";
import PageHeader from "../../../common/pageHeader";
import HeaderSection from "../../../common/headerSection";
import { Modal } from "react-bootstrap";
import CustomTable from "../../../../widgets/table";
import { columns } from "./partials/columns";
import { TfiClose } from "react-icons/tfi";
import { useTranslation } from "react-i18next";
import AlertModal from "../../../../widgets/alertModal";
import CustomToast from "../../../../widgets/toaster";
import Pagination from "../../../../widgets/pagination";
import { userService } from "../../../../service/userService";
import { deviceRegistration } from "../../../../service/deviceRegistration";
import moment from "moment";
import ShiftName from "./partials/shiftName";
import { shiftManagement } from "../../../../service/shiftManagement";

const ShiftRegister = () => {
  const { t } = useTranslation();
  const [showAddModal, setShowAddModal] = useState(false);
  const [userAddModal, setuserAddModal] = useState(false);
  const [modalName, setModalName] = useState(
    t("applicationConfiguration.addAssignUser")
  );
  const [modalEdit, setModalEdit] = useState(false);
  const [holidayRegister, setHolidayRegister] = useState({
    selectShift: "",
    fromDate: "",
    id: "",
    reason: "",
    shiftName: "",
    toDate: "",
  });
  interface DeviceRegisterItem {
    user_ids: string;
    id:string;
    shift_id:string;
  }
  const [deviceRegister, setDeviceRegister] = useState<DeviceRegisterItem[]>([]);
  const [addHolidayAlert, setAddHolidayAlert] = useState(false);
  const [addAssignAlert, setAddAssignAlert] = useState(false);
  const [useEffectRequired, setuseEffectRequired] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  // table pagination for sample type
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  // table pagination for sample Sub type
  interface Errors {
    selectShift?: string;
    fromDate?: string;
    toDate?: string;
    reason?: string;
    shiftName?: string;
    selectedIncubators?:string;
  }

  const [errors, setErrors] = useState<Errors>({});
  const [permission, setPermission] = useState<any>([]);
  const [HolidayOptionsList, setHolidayOptionsList] = useState<Array<any>>([]);
  const currentYear = new Date().getFullYear();
  const minDate = `${currentYear}-01-01`;
  // const maxDate = `${currentYear}-12-31`;
  const [keyWord, setkeyWord] = useState("");
  const [year, setYear] = useState("");
  const [statusChangeFlag, setStatusChangeFlag] = useState(false);
  const [tab, setTab] = useState("1");
  const [sort, setSort] = useState("id");
  const [order, setOrder] = useState("desc");

  const [incubatorList, setIncubatorList] = useState<Incubator[]>([]);
  const [selectedIncubators, setSelectedIncubators] = useState<Incubator[]>([]);
  // const [userData , setUserData] = useState<Array<any>>([]);
  const [addAssignData, setAddAssignData] = useState({
    id: "",
    user_ids : "",
    custom_shift_id: ""
  });
  useEffect(() => {
    var storedData = localStorage.getItem("userDetails");

    if (storedData) {
      var storedObject = JSON.parse(storedData);
      getPermissionByRole(storedObject[0].role_master_id);
    }

    listCustomShift();
    getShiftDropDown();
    getOperatorList();
    setuseEffectRequired(false);
  }, [useEffectRequired]);

  useEffect(() => {
    getShiftDropDown();
    setStatusChangeFlag(false);
  }, [statusChangeFlag]);

  //get permission by role
  const getPermissionByRole = async (role: number) => {
    try {
      const response = await userService.getPermissionByRole(role);
      if (response.status && response.statusCode === 200) {
        setPermission(response.data.permissions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //get all_raw_material_type list from api
  const listCustomShift = async () => {
    try {
      const response = await shiftManagement.listCustomShift(
        page,
        pageSize,
        keyWord,
        sort,
        order
      );
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            setTotalPages(response?.totalPages);
            setTotalElements(response?.totalElements);
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                const userIds = element.user_details?.map((user: any) => user.user_id).join(', ');
                return {
                  slno: index + 1,
                  id: element.id,
                  start_date: element.start_date,
                  end_date: element.end_date,
                  start_time: element.start_time,
                  end_time: element.end_time,
                  remarks: element.remarks,
                  shift_name:element.shift_name,
                  assigned_operator:element.assigned_user_count,
                  shift_id: element.shift_id,
                  user_ids: userIds 
                };
              }
            );
            console.log(rowData.user_ids , "userIDs")
            setDeviceRegister(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setDeviceRegister([]);
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  //create raw material type
  const createHoliday = async () => {
    setAddHolidayAlert(false);
    const dataToSend = {    
      id: holidayRegister.id,
      remarks: holidayRegister.shiftName,
      shift_id: holidayRegister.selectShift,
      start_date: holidayRegister.fromDate,
      end_date: holidayRegister.toDate,
      // reason: holidayRegister.reason,
      interface_name: "Add Holiday",
    };
    try {
      const response = await shiftManagement.createShifttoUser(dataToSend);
      if (response.status && response.statusCode === 200) {
        setToastType("success");
        setToastMessage(response.message);
        addModalCloseEvent();
      } else {
        setToastType("error");
        setToastMessage(response.message);
      }
      setShowToast(true);
      setuseEffectRequired(true);
    } catch (error) {
      console.error("user response", error);
    }
  };

  //handle policy management alert
  const handleAddAlert = () => {
    const errors: Errors = {};
    if (!holidayRegister.selectShift) {
      errors.selectShift = "Please Select Shift";
    }
    if (!holidayRegister.fromDate) {
      errors.fromDate = "Please Enter From Date";
    }
    if (!holidayRegister.toDate) {
      errors.toDate = "Please Enter To Date";
    }
    if (!holidayRegister.shiftName) {
      errors.shiftName = "Please Enter Shift Name";
    }
    if (!holidayRegister.reason && holidayRegister.id) {
      errors.reason = "Please Enter Reason";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      console.log(errors);
      return;
    }
    setAddHolidayAlert(true);
    setErrors({});
  };

  const handleAssignUserSubmit = async ()=> {
    setAddAssignAlert(false);
    const dataToSend = {    
      // id: addAssignData.id,
      user_ids: addAssignData.user_ids,
      custom_shift_id: addAssignData.id,
      interface_name: "Assign User",
    };
    try {
      const response = await shiftManagement.AssignUserShift(dataToSend);
      if (response.status && response.statusCode === 200) {
        setToastType("success");
        setToastMessage(response.message);
        onCancelAssignModal();
      } else {
        setToastType("error");
        setToastMessage(response.message);
      }
      setShowToast(true);
      setuseEffectRequired(true);
    } catch (error) {
      console.error("user response", error);
    }
  }

  const handleAssignAlert = () => {
    const errors: Errors = {};
    if (!addAssignData.user_ids) {
      errors.selectedIncubators = "Please Select Incubation Order";
    }    
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      console.log(errors);
      return;
    }
    setAddAssignAlert(true);
    setErrors({});
  };

  const handleSubmit = () => {
    setAddHolidayAlert(false);
    createHoliday();
  };

  const addHolidayClick = () => {
    setModalName(t("applicationConfiguration.addAssignUser"));
    setModalEdit(false);
    setShowAddModal(true);
  };

  const addModalCloseEvent = () => {
    setShowAddModal(false);
    setHolidayRegister({
      selectShift: "",
      fromDate: "",
      id: "",
      reason: "",
      shiftName: "",
      toDate: "",
    });
    setErrors({});
  };

  //handle table pagination
  const handlePageChange = (newPage: any, pageSize?: number) => {
    setPage(newPage);
    if (pageSize) {
      setPageSize(pageSize);
    }
    setuseEffectRequired(true);
  };

  //handle filter section in sample type
  const handleFilter = (key: string, value: any) => {
    if (key === "Role") {
      // setRole(value)
    } else if (key === "search") {
      setkeyWord(value);
    } else if (key === "selectedYear") {
      setYear(value);
    }
    setuseEffectRequired(true);
  };

  const getShiftDropDown = async () => {
    try {
      const response = await shiftManagement.getShiftDropDown();
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  slno: index + 1,
                  title: element.name,
                  id: element.id,
                  status: element.status,
                };
              }
            );
            setHolidayOptionsList(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditClick = (id: string) => {
    setModalName(t("applicationConfiguration.editAssignUser"));
    setModalEdit(true);
    const filteredUserDetails: any = deviceRegister.filter(
      (item: any) => item.slno === id
    );
    const formattedDate = moment(
      filteredUserDetails[0]?.date,
      "DD-MM-YYYY"
    ).format("YYYY-MM-DD");
    setHolidayRegister({
      selectShift: filteredUserDetails[0]?.shift_id,
      fromDate:  filteredUserDetails[0]?.start_date,
      id: filteredUserDetails[0]?.id,
      reason: filteredUserDetails[0]?.reason,
      shiftName: filteredUserDetails[0]?.remarks,
      toDate: filteredUserDetails[0]?.end_date,
    });
    setShowAddModal(true);
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    const errors: Errors = {};
    let regex;

    if (name === "shiftName") {
      regex = /^[a-zA-Z0-9-\s]*$/;
    // }else if (name === "reason") {
    //   regex = /^[a-zA-Z0-9-\s]*$/;
    // }
    }

    if ( name === "fromDate" && value > holidayRegister.toDate) {
      setHolidayRegister((prevFormData: any) => ({
        ...prevFormData,
        ["toDate"]: "",
      }));
    }

    if (value === "") {
    } else if (regex && !regex.test(value)) {
      return;
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    } else {
      setErrors({});
    }

    setHolidayRegister((prevFormData: any) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const checkSpecialChar =(e : any)=>{
    if(!/[0-9a-zA-Z]/.test(e.key)){
     e.preventDefault();
    }
  }

  const handleAssignUserClick = (id: string) => {
    setuserAddModal(true)
    getOperatorList();

    let orderIds: any = []
    const selectedDevice: any = deviceRegister.find((device) => device.id === id);
    if (selectedDevice) {
      orderIds = selectedDevice.user_ids.split(',').map((id: string) => id.trim());
    } 

  const filteredIncubators = incubatorList?.filter(incubator => orderIds.includes(incubator.id)) || [];

  // Filter out incubators that are in the filteredIncubators list
  const updatedIncubatorList = incubatorList?.filter(
    incubator => !filteredIncubators.some(fIncubator => fIncubator.id === incubator.id)
  ) || [];

  // Update the state with the filtered lists
  setSelectedIncubators(filteredIncubators);
  setIncubatorList(updatedIncubatorList);
  setAddAssignData((prevData) => ({
    ...prevData,
    id: selectedDevice?.id,
    custom_shift_id: selectedDevice?.shift_id
}));
  };

  // function to get operator api list
const getOperatorList = async () => {
  try { 
    const response = await shiftManagement.getUserNameList("");
    if(response.status && response.statusCode === 200){
      try {
          if ( Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map((element: any, index: number) => {

              return { 
                  slno: index + 1, 
                  id: element.id,
                  name: `${element.first_name} ${element.last_name}`,
              };
            });
            setIncubatorList(rowData);
          }
        } catch (error) {
          console.log(error);
        }
    } else {
      setIncubatorList([]);
    }
  } catch (error) {
    console.error(error);
  }
};

  interface Incubator {
    id: string;
    name: string;
  }

  interface IncubatorListProps {
    incubators: Incubator[];
    onIncubatorClick: (incubator: Incubator) => void;
  }

  const IncubatorList: React.FC<IncubatorListProps> = ({ incubators, onIncubatorClick }) => {
    return (
      <div className='mx-3 p-2' style={{border:"1px solid grey", height: "25vh", width:"150px", overflow:"scroll"}}>
        {incubators.slice().reverse().map((incubator) => (
          <p className='my-1' style={{background: "grey", color: "white", fontSize:"small"}} key={incubator.id} onClick={() => onIncubatorClick(incubator)}>
            {incubator.name}
          </p>
        ))}
      </div>
    );
  };

  const handleIncubatorClick = (clickedIncubator: Incubator) => {
    const updatedDefaultIncubators = incubatorList.filter(
      (incubator) => incubator.id !== clickedIncubator.id
    );


    setIncubatorList(updatedDefaultIncubators);
    setSelectedIncubators([clickedIncubator, ...selectedIncubators]);

  };

  const handleSelectedIncubatorClick = (clickedIncubator: Incubator) => {
    const updatedSelectedIncubators = selectedIncubators.filter(
      (incubator) => incubator.id !== clickedIncubator.id
    );

    setSelectedIncubators(updatedSelectedIncubators);
    setIncubatorList([clickedIncubator, ...incubatorList]);

  };

  const onCancelAssignModal = () => {
    setuserAddModal(false)
    setSelectedIncubators([])
    setAddAssignData({
      id: "",
      user_ids : "",
      custom_shift_id: ""
    })
    setuseEffectRequired(true);
  }
  

  useEffect(() => {
    const selectedIncubatorIdsString = selectedIncubators
      .map((incubator) => incubator.id)
      .join(',');

      setAddAssignData((prevData) => ({
        ...prevData,
        user_ids: selectedIncubatorIdsString
    }));
  }, [selectedIncubators])

  const handleSort = (key: string, value: boolean) => {
    setSort(key);
    if (value) {
      setOrder("asc");
    } else {
      setOrder("desc");
    }
    setuseEffectRequired(true);
  };

  return (
    <div>
      <HeaderSection />
      <PageHeader pageHeader={t("shiftManagement.shiftManagement")} />
      <CustomToast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={5000}
        message={toastMessage}
        toastType={toastType}
      />
      <div className="exceptionHandlingTab">
        <button
          onClick={() => setTab("1")}
          className={tab === "1" ? "colTabBtnSel" : "colTabBtn"}
        >
          {t("shiftManagement.addShift")}
        </button>
        <button
          onClick={() => {setTab("2"); setStatusChangeFlag(true);}}
          className={tab === "2" ? "colTabBtnSel" : "colTabBtn"}
        >
          {t("shiftManagement.assignUser")}
        </button>
      </div>

      {tab === "1" && <ShiftName setStatusChangeFlag={setStatusChangeFlag} />}
      {tab === "2" && (
        <div className="me-3" style={{ marginTop: "20px" }}>
          <div className=" mx-3 pe-2" style={{ height: "45vh" }}>
            <CustomTable
              tableHead={t("shiftManagement.assignUser")}
              data={deviceRegister}
              columns={columns}
              isEditable={permission?.includes("edit_shift")}
              isTempDeletable={false}
              totalElements={totalElements}
              isActionVisible={false}
              isViewVisible={false}
              isSeachable={true}
              isToggleStatus={false}
              isResetPassword={false}
              addButton={permission?.includes("add_shift")}
              addButtonText={t("shiftManagement.assignUser")}
              onUseEffectRequired={() => setuseEffectRequired(true)}
              onFilterClick={handleFilter}
              onAddClick={addHolidayClick}
              onAssignUserClick={handleAssignUserClick}
              changeRoleButton={false}
              onEditClick={handleEditClick}
              showassignUserButton={permission?.includes("add_shift")}
              onSortClick={handleSort}
            />
          </div>
          <div>
            <Pagination
              page={page}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
            />
          </div>
        </div>
      )}

      <Modal className="top-right-modal" centered={false} show={showAddModal}>
        <div>
          <div className="modalHead">
            <p className="modalHeadText ms-3 pt-3 p-2">{modalName}</p>
            <div
              onClick={() => addModalCloseEvent()}
              className="p-2 mx-3 my-2 "
            >
              <TfiClose fill="#FFFFFF" />
            </div>
          </div>

          <div>
            <div className="selectWrap">
              <div className="form-group my-1 ">
                <label htmlFor="">
                  {t("shiftManagement.detailName")}{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <input
                  id="shiftName"
                  value={holidayRegister.shiftName}
                  name="shiftName"
                  type="text"
                  placeholder={t("shiftManagement.detailName")}
                  onChange={handleInputChange}
                  className="placeholder-style"
                />
                {errors.shiftName && (
                  <p className="errorText">{errors.shiftName}</p>
                )}
              </div>

              <div className="form-group my-1">
                <label htmlFor="">
                  {t("shiftManagement.selectShift")}{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <select
                  id="selectShift"
                  value={holidayRegister.selectShift}
                  name="selectShift"
                  onChange={handleInputChange}
                >
                  <option value="">Select</option>
                  {HolidayOptionsList.map((obj, index) => (
                    <option key={index} value={obj?.id}>
                      {obj?.title}
                    </option>
                  ))}
                </select>
                {errors.selectShift && (
                  <p className="errorText">{errors.selectShift}</p>
                )}
              </div>
            </div>

            <div className="selectWrap">
              <div className="form-group my-1">
                <label htmlFor="">
                  {t("shiftManagement.start_date")}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <input
                  id="fromDate"
                  value={holidayRegister.fromDate}
                  name="fromDate"
                  type="date"
                  placeholder={t("shiftManagement.start_date")}
                  className="placeholder-style"
                  onChange={handleInputChange}
                  maxLength={100}
                  min={new Date().toISOString().split("T")[0]}
                  onKeyDown={(e) => {
                    const allowedKeys = ["Tab", "Enter", "Escape"];
                    if (!allowedKeys.includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  onPaste={(e) => e.preventDefault()}
                />
                {errors.fromDate && (
                  <p className="errorText">{errors.fromDate}</p>
                )}
              </div>

              <div className="form-group my-1">
                <label htmlFor="">
                  {t("shiftManagement.end_date")}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <input
                  id="toDate"
                  value={holidayRegister.toDate}
                  name="toDate"
                  type="date"
                  placeholder={t("shiftManagement.end_date")}
                  className="placeholder-style"
                  onChange={handleInputChange}
                  maxLength={100}
                  disabled={holidayRegister.fromDate ? false : true}
                  min={holidayRegister.fromDate}
                  onKeyDown={(e) => {
                    const allowedKeys = ["Tab", "Enter", "Escape"];
                    if (!allowedKeys.includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  onPaste={(e) => e.preventDefault()}
                />
                {errors.toDate && <p className="errorText">{errors.toDate}</p>}
              </div>
            </div>

            <div className="selectWrap-email">
              {holidayRegister.id && (
                <div className="form-group my-1 ">
                  <label htmlFor="" className="ReasonLabel">
                    {t("userManagement.reason")}{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <br />
                  <input
                    id="reason"
                    value={holidayRegister.reason}
                    name="reason"
                    type="text"
                    placeholder={t("userManagement.reason")}
                    className="reasonClass"
                    onChange={handleInputChange}
                    onKeyDown={(e)=>checkSpecialChar(e)}
                  />
                  {errors.reason && (
                    <p className="errorText">{errors.reason}</p>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="bottomArea">
            <button
              onClick={() => addModalCloseEvent()}
              className="modalCancelBtn"
            >
              {t("buttons.cancel")}
            </button>
            <button
              onClick={() => handleAddAlert()}
              className="squareOrangeBtn3"
            >
              {t("buttons.submit")}
            </button>
          </div>
        </div>
      </Modal>

      <Modal className="top-right-modal" centered={false} show={userAddModal}>
        <div>
          <div className="modalHead">
            <p className="modalHeadText ms-3 pt-3 p-2">Assign User</p>
            <div
               onClick={()=>onCancelAssignModal()}
              className="p-2 mx-3 my-2 "
            >
              <TfiClose fill="#FFFFFF" />
            </div>
          </div>

          <div className="selectWrap">
                <div className="d-flex my-2">
                  <div className='form-group my-3 mx-3 px-2' style={{marginLeft:"5%"}}>
                    <p> User List</p>
                    <IncubatorList incubators={incubatorList} onIncubatorClick={handleIncubatorClick} />                
                  </div>

                  <div className='form-group my-3 mx-5 px-2' style={{marginLeft:"10%"}}>
                    <p>Selected Users <span style={{ color: 'red' }}>*</span></p>
                    <IncubatorList incubators={selectedIncubators} onIncubatorClick={handleSelectedIncubatorClick} />
                    {errors.selectedIncubators && <p className="errorText">{errors.selectedIncubators}</p>}
                  </div>
                </div>
          </div>
          <div className="bottomArea">
            <button
              onClick={()=>onCancelAssignModal()}
              className="modalCancelBtn"
            >
              {t("buttons.cancel")}
            </button>
            <button
              onClick={() => handleAssignAlert()}
              className="squareOrangeBtn3"
            >
              {t("buttons.submit")}
            </button>
          </div>
        </div>
      </Modal>

      {/* Alert modal for Add user shift */}
      <AlertModal
        show={addHolidayAlert}
        onHide={() => setAddHolidayAlert(false)}
        title="Alert"
        message={`${modalName}`}
        onConfirm={handleSubmit}
      />

      <AlertModal
        show={addAssignAlert}
        onHide={() => setAddAssignAlert(false)}
        title="Alert"
        message={`Assign Users`}
        onConfirm={handleAssignUserSubmit}
      />
    </div>
  );
};

export default ShiftRegister;
