import React, { useEffect, useState } from 'react'
import CustomToast from '../../../../../widgets/toaster'
import { useTranslation } from 'react-i18next'
import CustomTable from '../../../../../widgets/table'
import { deviceRegistration } from '../../../../../service/deviceRegistration'
import Pagination from '../../../../../widgets/pagination'
import { HolidayNamecolumns } from './columns'
import { userService } from '../../../../../service/userService'
import { TfiClose } from 'react-icons/tfi'
import { Modal } from 'react-bootstrap'
import AlertModal from '../../../../../widgets/alertModal'
import { shiftManagement } from '../../../../../service/shiftManagement'


interface AddScheduleModalProps {
  setStatusChangeFlag : (time: boolean) => void;
}

const ShiftName : React.FC<AddScheduleModalProps> = ({setStatusChangeFlag}) => {

    const { t } = useTranslation();
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastType, setToastType] = useState("");
    const [useEffectRequired, setuseEffectRequired] = useState(false)
    const [permission, setPermission] = useState<any>([]);

    const [deviceRegister, setDeviceRegister] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [totalPages, setTotalPages] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const currentYear = new Date().getFullYear();
    const minDate = `${currentYear}-01-01`;
    const [keyWord, setkeyWord] = useState("")
    const [showAddModal, setShowAddModal] = useState(false);
    const [modalName, setModalName] = useState(t('shiftManagement.addShift'));
    const [masterNameRegister, setMasterNameRegister] = useState({
      id: "",
      reason:"",
      name:"",
      start_time: "",
      end_time: "",
      duration:""
    });
    const [statusAlert, setStatusAlert] = useState(false)
    const [updatedStatus, setUpdatedStatus] = useState("")
    const [statusId, setStatusId] = useState("")
    interface Errors {
        name?: string,
        reason?: string,
        start_time?: string,
        end_time?: string,
      }
      
      const [errors, setErrors] = useState<Errors>({});
      const [addMasterAlert, setAddMasterAlert] = useState(false);
      const [modalEdit, setModalEdit] = useState(false);
      const [sort, setSort] = useState("id");
      const [order, setOrder] = useState("desc");

    useEffect(() => {

    var storedData = localStorage.getItem('userDetails');

    if(storedData) {
        var storedObject = JSON.parse(storedData);
        getPermissionByRole(storedObject[0].role_master_id);
    }

    listMasterShiftName();
    setuseEffectRequired(false)
    }, [useEffectRequired])
  
      
 //get all_raw_material_type list from api  
 const listMasterShiftName = async () => {
    try {
      const response = await shiftManagement.listMasterShiftName(page, pageSize, keyWord, sort , order);
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              setTotalPages(response?.totalPages)
              setTotalElements(response?.totalElements)
              let rowData: any = response.data.map((element: any, index: number) => {


                function timeToMilliseconds(time: string): number {
                  const [hours, minutes, seconds] = time.split(':').map(Number);
                  return (hours * 3600 + minutes * 60 + seconds) * 1000;
              }
              
              const start_time = element.start_time; // "04:00:00"
              const end_time = element.end_time;     // "08:00:00"
              
              // Convert times to milliseconds
              const startMilliseconds = timeToMilliseconds(start_time);
              const endMilliseconds = timeToMilliseconds(end_time);
              
              // Calculate the difference in milliseconds
              const differenceInMilliseconds = endMilliseconds - startMilliseconds;
              
              // Convert the difference from milliseconds to hours
              const durationInHours = differenceInMilliseconds / (1000 * 60 * 60);
              
              // Store the duration, rounded to two decimal places
              const duration = durationInHours.toFixed(2);
                return { 
                    slno: index + 1, 
                    shift_name: element.name,
                    id : element.id,
                    start_time: element.start_time,
                    end_time: element.end_time,
                    duration: duration,
                    status : element.status
                };
              });
              setDeviceRegister(rowData);
            }
          } catch (error) {
            console.log(error);
          }
      }else {
        setDeviceRegister([]);
      }
    } catch (error) {
      console.error('user response', error);
    }
  }

  const handlePageChange = (newPage : any, pageSize?: number) => {
    setPage(newPage)
    if(pageSize) {
      setPageSize(pageSize)
    }
    setuseEffectRequired(true)
  };

  const handleFilter = (key: string, value: any) => {
    if(key === "Role") {
      // setRole(value)
    } else if (key === "search") {
      setkeyWord(value)
    }
    setuseEffectRequired(true)
  }

    //get permission by role 
    const getPermissionByRole = async (role : number) => {
        try {
            const response = await userService.getPermissionByRole(role);
            if(response.status && response.statusCode === 200){
            setPermission(response.data.permissions)
            }
        } catch (error) {
            console.error(error);
        }
    }

    const addMasterNameClick = () => {
    setModalName(t('shiftManagement.addShift'))
    setShowAddModal(true)
    setModalEdit(false)
    }

    const addModalCloseEvent = () => {
        setShowAddModal(false)
        setMasterNameRegister({
          name: "",
          id: "",
          reason: "",
          start_time: "",
          end_time: "",
          duration:""
          })
        setErrors({})
    }

    const handleSaveNewName = async () => {
    const dataToSend = {   
        name : masterNameRegister.name, 
        id: masterNameRegister.id, 
        reason : masterNameRegister.reason,
        start_time: masterNameRegister.start_time,
        end_time: masterNameRegister.end_time,
        interface_name: "Add Shift Name",
    }
    try {
        const response = await shiftManagement.addMasterShiftData(dataToSend);
        if(response.status && response.statusCode === 200){
          setToastType("success")
          setToastMessage(response.message)
          addModalCloseEvent();
          setShowAddModal(false)
        } else {
          setToastType("error")
          setToastMessage(response.message)
        }
        setAddMasterAlert(false)
        setShowToast(true)
        setuseEffectRequired(true)
      } catch (error) {
        console.error('user response', error);
      }
  };

  const handleAddAlert = () => {
    const errors: Errors = {};
      if (!masterNameRegister.name) {
        errors.name = "Please Enter Shift Name";
      }
      if (masterNameRegister.id && !masterNameRegister.reason) {
        errors.reason = "Please Enter Reason";
      }
      if (!masterNameRegister.start_time) {
        errors.start_time = "Please Enter Start Time";
      }
      if (!masterNameRegister.end_time) {
        errors.end_time = "Please Enter End Time";
      }
      
      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        console.log(errors)
        return;
      }
      setAddMasterAlert(true)
      setErrors({});
  }

  const handleStatusChange = (policy_id:any , status: string) => {
    setStatusAlert(true)
    setStatusId(policy_id)
      if (status === "active") {
        setUpdatedStatus("inactive")
      } else {
        setUpdatedStatus("active")
      }
  }

  //handle policy status change 
  const handleStatusChangeSubmit = async () => {
      try {
        const response = await shiftManagement.changeMasterShiftStatus(statusId, updatedStatus, "Master Shift Name Status");
        
        if (response.status && response.statusCode === 200) {
          setToastType("success")
          setToastMessage(response.message)
          setStatusChangeFlag(true)
        }
        else {
          setToastType("error")
          setToastMessage(response.message)
        }
        setShowToast(true)
        listMasterShiftName();
        setUpdatedStatus("")
        setStatusId("")
        setStatusAlert(false)
      } catch (error) {
        console.error(error);
      }
  }

  const handleEditClick = (id: string) => {
    setModalName(t('shiftManagement.editShift'))
    setModalEdit(true)
    const filteredUserDetails: any = deviceRegister.filter((item: any) => item.slno === id);
    setMasterNameRegister({
      name: filteredUserDetails[0]?.shift_name,
      id: filteredUserDetails[0]?.id,
      reason: filteredUserDetails[0]?.reason,
      start_time: filteredUserDetails[0]?.start_time,
      end_time: filteredUserDetails[0]?.end_time,
      duration: filteredUserDetails[0]?.duration
    })
    setShowAddModal(true)
  }

  const handleInputChange = (e : any) => {
    const { name, value } = e.target;
    const errors: Errors = {};
    let regex;

    if (name === "name") {
      regex = /^[a-zA-Z0-9-\s]*$/;
    // }else if (name === "reason") {
    //   regex = /^[a-zA-Z0-9-\s]*$/;
    // }
    }

    if (value === "") {
    } else if (regex && !regex.test(value)) {
      return;
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    } else {
      setErrors({});
    }
    setMasterNameRegister((prevFormData : any) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleChangeWithValidation = (e: any) => {
    let inputValue = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
    if (inputValue.length > 6) {
      inputValue = inputValue.slice(0, 6); // Limit to 6 characters (hhmmss)
    }
    let name = e.target.name;
  
    let formattedTime = inputValue;
  
    // Insert colons to format as hh:mm:ss
    if (inputValue.length > 2) {
      formattedTime = inputValue.slice(0, 2) + ":" + inputValue.slice(2);
    }
    if (inputValue.length > 4) {
      formattedTime = formattedTime.slice(0, 5) + ":" + formattedTime.slice(5);
    }
  
    setMasterNameRegister({
      ...masterNameRegister,
      [name]: formattedTime,
    });
  
    const { start_time, end_time } = masterNameRegister;
  
    if (start_time && end_time) {
      const [startHours, startMinutes, startSeconds] = start_time.split(":").map(Number);
      const [endHours, endMinutes, endSeconds] = end_time.split(":").map(Number);
  
      // Check if the times are valid numbers
      if (
        !isNaN(startHours) && !isNaN(startMinutes) && !isNaN(startSeconds) &&
        !isNaN(endHours) && !isNaN(endMinutes) && !isNaN(endSeconds)
      ) {
        const startDate = new Date();
        startDate.setHours(startHours, startMinutes, startSeconds);
  
        const endDate = new Date();
        endDate.setHours(endHours, endMinutes, endSeconds);
  
        // Calculate the difference in milliseconds and convert to hours
        const durationInMs = endDate.getTime() - startDate.getTime();
        const durationInHours = durationInMs / (1000 * 60 * 60);
  
        // Store the calculated duration or show a placeholder
        setMasterNameRegister({
          ...masterNameRegister,
          [name]: formattedTime,
          duration: durationInHours > 0 ? durationInHours.toFixed(2) : "--", // Use placeholder if duration is negative or zero
        });
      } else {
        // Set placeholder if time values are invalid
        setMasterNameRegister({
          ...masterNameRegister,
          [name]: formattedTime,
          duration: "--",
        });
      }
    }
  };
  

  const handleSort = (key: string, value: boolean) => {
    setSort(key);
    if (value) {
      setOrder("asc");
    } else {
      setOrder("desc");
    }
    setuseEffectRequired(true);
  };

  const checkSpecialChar =(e : any)=>{
    if(!/[0-9a-zA-Z]/.test(e.key)){
     e.preventDefault();
    }
  }

  return (
    <div>
      <CustomToast
            show={showToast}
            onClose={() => setShowToast(false)}
            delay={5000}
            message={toastMessage}
            toastType={toastType}
        /> 
   
        <div className='me-3' style={{marginTop : "20px"}}>
            <div className=' mx-3 pe-2' style={{"height":"45vh"}}>
                <CustomTable 
                    tableHead={t('shiftManagement.addShift')}
                    data={deviceRegister} 
                    columns={HolidayNamecolumns} 
                    isEditable={permission?.includes("edit_shift")}
                    isTempDeletable={false}
                    totalElements={totalElements} 
                    isActionVisible={false} 
                    isViewVisible={false} 
                    isSeachable={true}
                    isToggleStatus={permission?.includes("edit_shift")}
                    isResetPassword={false}
                    addButton={permission?.includes("add_shift")}
                    addButtonText={t('shiftManagement.addShift')}
                    onUseEffectRequired={() => setuseEffectRequired(true)}
                    onFilterClick={handleFilter}
                    onAddClick={addMasterNameClick}             
                    changeRoleButton={false}
                    onStatusChange={handleStatusChange}
                    onEditClick={handleEditClick}
                    onSortClick={handleSort}
                />
            </div>
            <div>
              <Pagination
                page={page}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
              />
            </div>
        </div>


        <Modal 
              className="top-right-modal"
              centered={false}
              show={showAddModal}
          >
            <div>
              <div className="modalHead">
                  <p className="modalHeadText ms-3 pt-3 p-2">{modalName}</p>
                  <div onClick={() => addModalCloseEvent()} className="p-2 mx-3 my-2 ">
                    <TfiClose fill="#FFFFFF" />
                  </div>
              </div>

            <div>
            <div className= "selectWrap">
                <div className="form-group my-1">                    
                <label htmlFor="" >{t('shiftManagement.shiftName')}<span style={{ color: 'red' }}>*</span></label>
                <br />
                <input 
                    id="name"
                    value={masterNameRegister.name}
                    type='text'
                    name="name"
                    placeholder={t('shiftManagement.shiftName')}
                    className="placeholder-style"
                    onChange={handleInputChange}
                />
                {errors.name && <p className="errorText">{errors.name}</p>}
                </div>


                <div className="form-group my-1">                    
                <label htmlFor="" >{t('shiftManagement.start_time')}<span style={{ color: 'red' }}>*</span></label>
                <br />
                <input 
                    id="start_time"
                    value={masterNameRegister.start_time}
                    name="start_time"
                    type='text'
                    placeholder='hh:mm:ss'
                    className="placeholder-style"
                    onChange={handleChangeWithValidation}
                    maxLength={8}
                />
                {errors.start_time && <p className="errorText">{errors.start_time}</p>}
                </div>
            </div>

            <div className= "selectWrap">
                <div className="form-group my-1">                    
                <label htmlFor="" >{t('shiftManagement.end_time')}<span style={{ color: 'red' }}>*</span></label>
                <br />
                <input 
                    id="end_time"
                    value={masterNameRegister.end_time}
                    name="end_time"
                    type='text'
                    placeholder='hh:mm:ss'
                    className="placeholder-style"
                    onChange={handleChangeWithValidation}
                    maxLength={8}
                />
                {errors.end_time && <p className="errorText">{errors.end_time}</p>}
                </div>


                <div className="form-group my-1">                    
                <label htmlFor="" >{t('shiftManagement.duration')}</label>
                <br />
                <input 
                    id="duration"
                    value={masterNameRegister.duration}
                    type='text'
                    name="duration"
                    placeholder={t('shiftManagement.duration')}
                    className="placeholder-style"
                    onChange={handleInputChange}
                    disabled
                />
                {/* {errors.name && <p className="errorText">{errors.name}</p>} */}
                </div>
            </div>

            <div className="selectWrap-email">           
                      {masterNameRegister.id && (
                      <div className="form-group my-1 ">
                        <label htmlFor="" className="ReasonLabel">
                          {t("userManagement.reason")}{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <br />
                        <input
                          id="reason"
                          value={masterNameRegister.reason}
                          name="reason"
                          type="text"
                          placeholder={t("userManagement.reason")}
                          className="reasonClass"
                          onChange={handleInputChange}
                          onKeyDown={(e)=>checkSpecialChar(e)}
                        />
                        {errors.reason && (
                          <p className="errorText">{errors.reason}</p>
                        )}
                      </div>
                    )}
            </div> 

          </div>
                <div className="bottomArea">
                  <button onClick={() => addModalCloseEvent()} className="modalCancelBtn">{t('buttons.cancel')}</button>
                  <button onClick={() => handleAddAlert()} className="squareOrangeBtn3">{t('buttons.submit')}</button>
                </div>
            </div>
          
        </Modal>

        <AlertModal 
          show={addMasterAlert}
          onHide={() => setAddMasterAlert(false)}
          title='Alert'
          message={`${modalName}`}
          onConfirm={handleSaveNewName}
        />

        <AlertModal 
          show={statusAlert}
          onHide={() => setStatusAlert(false)}
          title='Alert'
          message={`Change Shift status`}
          onConfirm={handleStatusChangeSubmit}
        />
       
    </div>
  )
}

export default ShiftName;
