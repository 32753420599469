import React, { useEffect, useState } from 'react'
import HeaderSection from '../../../common/headerSection'
import PageHeader from '../../../common/pageHeader'
import CustomToast from '../../../../widgets/toaster'
import { useTranslation } from 'react-i18next'
import CustomTable from '../../../../widgets/table'
import Pagination from '../../../../widgets/pagination'
import { columns, opColumns } from './partials/columns'
import { Button, Modal } from 'react-bootstrap'
import { TfiClose } from 'react-icons/tfi'
import { scheduleService } from '../../../../service/scheduleService'
import { helperFunctions as _h } from "../../../../utils/helper";
import baseUrl from '../../../../service'
import moment from 'moment'
import { areaService } from '../../../../service/areaServices'
import { workflow } from '../../../../service/workflow'



let fakeData: any = [
    {
      slno: 1,
      id: 1,
      activity: "Activity 1",
      process: "Process 1",
      area: "Area 1",
      location: "Location 1",
      dateTime: "2024-10-21 10:00 AM",
      status: "Pending",
      assignee: "John Doe",
      plateSerialNo: "XYZ123",
    },
    {
      slno: 2,
      id: 2,
      activity: "Activity 2",
      process: "Process 2",
      area: "Area 2",
      location: "Location 2",
      dateTime: "2024-10-21 11:00 AM",
      status: "Completed",
      assignee: "Jane Smith",
      plateSerialNo: "ABC456",
    },
    {
      slno: 3,
      id: 3,
      activity: "Activity 3",
      process: "Process 3",
      area: "Area 3",
      location: "Location 3",
      dateTime: "2024-10-21 12:00 PM",
      status: "InProgress",
      assignee: "Mike Johnson",
      plateSerialNo: "DEF789",
    },
    {
      slno: 4,
      id: 4,
      activity: "Activity 4",
      process: "Process 4",
      area: "Area 4",
      location: "Location 4",
      dateTime: "2024-10-21 01:00 PM",
      status: "Pending",
      assignee: "Emily Clark",
      plateSerialNo: "GHI012",
    },
    {
      slno: 5,
      id: 5,
      activity: "Activity 5",
      process: "Process 5",
      area: "Area 5",
      location: "Location 5",
      dateTime: "2024-10-21 02:00 PM",
      status: "Completed",
      assignee: "Chris Lee",
      plateSerialNo: "JKL345",
    },
  ];


const GenerateQrPlate = () => {

    const { t } = useTranslation();

    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split('T')[0]; // YYYY-MM-DD
    const formattedTime = currentDate.toTimeString().split(' ')[0]; // HH:MM:SS
    const dateTime = `${formattedDate} 00:00:00`;

    // end date should be 1 week ahead from currentDate
    const endDate = new Date(currentDate);
    endDate.setDate(endDate.getDate() + 7);
    const formattedEndDate = endDate.toISOString().split('T')[0]; // YYYY-MM-DD
    const formattedEndTime = currentDate.toTimeString().split(' ')[0]; // HH:MM:SS
    const endDateTime = `${formattedEndDate} 23:59:59`;

    //toaster section
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastType, setToastType] = useState("");

    // table pagination
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [totalPages, setTotalPages] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
     // table pagination
    const [opPage, setOpPage] = useState(1);
    const [opPageSize, setOpPageSize] = useState(5);
    const [opTotalPages, setOpTotalPages] = useState(0);
    const [opTotalElements, setOpTotalElements] = useState(0);
    const [cellWidth, setCellWidth] = useState(1);


    const [assignedTaskList, setAssignedTaskList] = useState([]);
    const [activityType, setActivityType] = useState<Array<any>>([]);
    const [processList, setProcessList] = useState<Array<any>>([]);
    const [showGenerateQrModal, setShowGenerateQrModal] = useState(false);
    const [useEffectRequired, setUseEffectRequired] = useState(false);
    const [batchList, setBatchList] = useState<Array<any>>([]);
    const [usersList, setUsersList] = useState<Array<any>>([]);
    const [actionClearAll, setActionClearAll] = useState(false);
    const [areaData, setAreaData] = useState([]);
    const [locationList, setLocationList] = useState([]);


    //handle error section
    interface Errors {
        id?: string;
        activity_type_id?: string;
        reason?: string;
        status?: string;
        created_at?: string;
        updated_at?: string;
        created_by?: string;
        updated_by?: string;
        activity_type_name?: string;
        area_grade_name?: string;
        production_schedule_name?: string;
        product_name?: string;
        production_type?: string;
        area_id?: string;
        from_date_time?: string;
        to_date_time?: string;
        area_name?: string;
        batchId?: string;
        lotId?: string;
        nextSerialNo?: string;
        plateCount?: string;
    }
    const defaultQrFormData = {
        id: "",
        activity_type_id: "",
        batchId: "",
        lotId: "",
        nextSerialNo: "",
        plateCount: "",
      };
      const defaultFormData = {
        id: "",
        activity_type_id: "",
        reason: "",
        status: "",
        created_at: "",
        updated_at: "",
        created_by: "",
        updated_by: "",
        activity_type_name: "",
        area_grade_name: "",
        production_schedule_name: "",
        product_name: "",
        production_type: "",
        area_id: "",
        from_date_time: "",
        to_date_time: "",
        area_name: "",
      };
    const [errors, setErrors] = useState<Errors>({});
    const [lotList, setLotList] = useState<Array<any>>([]);
    const [totalPlateCount, setTotalPlateCount] = useState(0);
    const [opTableData, setOpTableData] = useState<Array<any>>([]);
    const [selectedOpSlNo, setSelectedOpSlNo] = useState<Array<any>>([]);
    const [qrFormData, setQrFormData] = useState(defaultQrFormData);
    const [selectedRow, setSelectedRow] = useState(defaultFormData);
    const [modalName, setModalName] = useState("");
    const [qrModalShow, setQrModalShow] = useState(false);
    const [qrFiles, setQrFiles] = useState<string[]>([]);
    const [activityTypeId, setActivityTypeId] = useState("");
    const [processId, setProcessId] = useState("");
    const [proScheduleId, setProScheduleId] = useState("")
    const [assignee, setAssignee] = useState("");
    const [location, setLocation] = useState("");
    const [areaId, setAreaId] = useState("");
    const [status, setStatus] = useState("active,inactive");
    const [fromDate, setFromDate] = useState(dateTime);
    const [toDate, setToDate] = useState(endDateTime);
    const [keyWord, setkeyWord] = useState("");
    const [sort, setSort] = useState("id");
    const [order, setOrder] = useState("desc");
    const [selectedSlnoLen, setSelectedSlnoLen] = useState(0);










    //handle table pagination
    const handlePageChange = (newPage: any, pageSize?: number) => {
        setPage(newPage);
        if (pageSize) {
        setPageSize(pageSize);
        }
        setActionClearAll(true)
        setUseEffectRequired(true);
    };


    // get table data
    const listAssignedTask = async () => {
        try {
        const response = await scheduleService.getOperatorScheduleList(
            page,
            pageSize,
            sort,
            order,
            keyWord,
            proScheduleId,
            activityTypeId,
            processId,
            status,
            assignee,
            fromDate,
            toDate,
            areaId,
            location
        );
        if (response.status && response.statusCode === 200) {
            try {
            if (Array.isArray(response.data) && response.data?.length > 0) {
                setTotalPages(response?.totalPages);
                setTotalElements(response?.totalElements);
                let rowData: any = response.data.map(
                (element: any, index: number) => {
                    return {
                    slno: index + 1,
                    id: element.id,
                    status: element.status,
                    activity_type_id: element.activity_type_id,
                    plateSerialNo: element.serial_number,
                    created_at: element?.created_at
                        ? moment(element?.created_at, "YYYY-MM-DD HH:mm:ss").format(
                            "DD-MM-YYYY - HH:mm:ss"
                        )
                        : "-",
                    updated_at: element?.updated_at
                        ? moment(element?.updated_at, "YYYY-MM-DD HH:mm:ss").format(
                            "DD-MM-YYYY - HH:mm:ss"
                        )
                        : "-",
                    schedule_production_id: element?.schedule_production_id,
                    start_date_time: element?.start_date_time
                        ? moment(
                            element?.start_date_time,
                            "YYYY-MM-DD HH:mm:ss"
                        ).format("DD-MM-YYYY")
                        : "-",
                    end_date_time: element?.end_date_time
                        ? moment(
                            element?.end_date_time,
                            "YYYY-MM-DD HH:mm:ss"
                        ).format("DD-MM-YYYY")
                        : "-",
                    process_id: element?.process_id,
                    user_master_id: element?.user_master_id,
                    created_by: element?.created_by,
                    updated_by: element?.updated_by,
                    production_schedule_name: element?.production_schedule_name,
                    product_name: element?.product_name,
                    production_type: element?.production_type,
                    user_first_name: element?.user_first_name,
                    user_last_name: element?.user_last_name,
                    // operator_name: (
                    //     (element?.user_first_name).trim() +
                    //     " " +
                    //     (element?.user_last_name).trim()
                    // ).trim(),
                    operator_name: `${element?.user_first_name || ""} ${element?.user_last_name || ""}`,
                    process_name: element?.process_name,
                    activity_type_name: element?.activity_type_name,
                    area_name: element?.area_name,
                    location_name: element?.location_name,
                    location_id: element?.location_id,
                    work_shift_name: element?.work_shift_name,
                    };
                }
                );
                setAssignedTaskList(rowData);
            }
            } catch (error) {
            console.log(error);
            }
        } else {
            setAssignedTaskList([]);
            setTotalPages(0);
            setTotalElements(0);
        }
        } catch (error) {
        console.error("user response", error);
        }
    };

    //api call to get lot list
    const getLotList = async (batchId: string) => {
        let rowData: any = [];
        try {
        const response = await scheduleService.getLotList(
            _h.isNotEmpty(batchId) ? batchId : defaultQrFormData?.batchId
        );
        if (response.status && response.statusCode === 200) {
            try {
            if (Array.isArray(response.data) && response.data?.length > 0) {
                rowData = response.data.map((element: any, index: number) => {
                return {
                    id: element.id,
                    name: element.lot_code,
                    remaining_plates_count: element?.remaining_plates_count
                };
                });
                setLotList(rowData);
            }
            } catch (error) {
            console.log(error);
            }
        } else {
            setLotList([]);
        }
        } catch (error) {
        console.error("user response", error);
        }
        return rowData;
    };

    //api call to get batch list
    const getBatchList = async () => {
        try {
        const response = await scheduleService.getBatchList();
        if (response.status && response.statusCode === 200) {
            try {
            if (Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: any = response.data.map(
                (element: any, index: number) => {
                    return {
                    id: element.id,
                    name: element.batch_no,
                    };
                }
                );
                setBatchList(rowData);
            }
            } catch (error) {
            console.log(error);
            }
        } else {
            setBatchList([]);
        }
        } catch (error) {
        console.error("user response", error);
        }
    };

    // get locations List
    const getAreaList = async () => {
        try {
          const response = await areaService.getAreaList(0, 0, sort , order , "", "8,12,9,6");
          if(response.status && response.statusCode === 200){
            try {
                if ( Array.isArray(response.data) && response.data?.length > 0) {
                  let rowData: any = response.data.map((element: any, index: number) => {
  
                    const role = element.role_master_id === "1" ?
                    "Admin" : element.role_master_id === "2" ?
                    "Operator" : element.role_master_id === "3"? 
                    "Lab Operator": null
                    const statusNumber = parseInt(element.type, 10); 
                    const area_type = areaService.getLocationStatus(statusNumber);
  
                    return { 
                        slno: index + 1, 
                        id: element.id,
                        area_code: element.area_code,
                        name: element.name,
                        area_type: element.location_type_name,
                        status : element.status,
                        qr_image_path : element.qr_image_path,
                    };
                  });
                  setAreaData(rowData);
                }
              } catch (error) {
                console.log(error);
              }
          } else {
            setAreaData([]);
          }
        } catch (error) {
          console.error('user response', error);
        }
    };

    // get Activity Type List
    const getActivityTypeList = async () => {
        let activityIds = "1,2,4,7"
        try {
        const response = await workflow.ActivityList(activityIds);
        if (response.status && response.statusCode === 200) {
            try {
            if (Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: any = response.data.map(
                (element: any, index: number) => {
                    return {
                    slno: index + 1,
                    id: element.id,
                    name: element.name,
                    };
                }
                );
                setActivityType(rowData);
            }
            } catch (error) {
            console.log(error);
            }
        } else {
            setActivityType([]);
        }
        } catch (error) {
        console.error("user response", error);
        }
    };

    // get MasterProcessList
    const getMasterProcessList = async (activity_type_id: string) => {
        let rowData: any = [];
        try {
        if (_h.isNotEmpty(activity_type_id)) {
            const response = await scheduleService.get_process_by_activity(activity_type_id);
            if (response.status && response.statusCode === 200) {
            try {
                if (Array.isArray(response.data) && response.data?.length > 0) {
                rowData = response.data.map((element: any, index: number) => {
                    return {
                    slno: index + 1,
                    id: element.id,
                    name: element.name,
                    };
                });
                setProcessList(rowData);
                }
            } catch (error) {
                console.log(error);
                setProcessList([]);
            }
            } else {
            setProcessList([]);
            }
        } else {
            setProcessList([]);
        }
        } catch (error) {
        console.error("user response", error);
        }
        return rowData;
    };

    // getMasterUserList()
    const getMasterUserList = async () => {
        try {
        const response = await scheduleService.getMasterUserList();
        if (response.status && response.statusCode === 200) {
            try {
            if (Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: any = response.data.map(
                (element: any, index: number) => {
                    return {
                    slno: index + 1,
                    id: element.id,
                    name: (
                        (element?.first_name).trim() +
                        " " +
                        (element?.last_name).trim()
                    ).trim(),
                    };
                }
                );
                setUsersList(rowData);
            }
            } catch (error) {
            console.log(error);
            }
        } else {
            setUsersList([]);
        }
        } catch (error) {
        console.error("user response", error);
        }
    };

    // get location by Activity 
    const getLocationByActivity = async (id: any) => {
        try {
        const response = await areaService.getLocationByActivity(id);
        if (response.status && response.statusCode === 200) {
            try {
            if (Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: any = response.data.map(
                (element: any, index: number) => {
                    return {
                    slno: index + 1,
                    id: element.id,
                    name: element.name,
                    };
                }
                );
                setLocationList(rowData);
            }
            } catch (error) {
            console.log(error);
            }
        } else {
            setLocationList([]);
        }
        } catch (error) {
            setLocationList([]);
        console.error("user response", error);
        }
    };


    const handleGeneratePlateQR = (slno: string[]) => {
        setModalName(t("generatePlateQr.generatePlateQr"));
        const updatedPlateDetails = assignedTaskList
        .filter((item: any) => slno.includes(item.slno))
        .map((item: any, index: number) => ({
            ...item,
            slno: index + 1 // Update slno with the new value
        }));
        setOpTableData(updatedPlateDetails)
        const anyHasPlateSerialNo = updatedPlateDetails.some(item => item.plateSerialNo && item.plateSerialNo !== "");
        const anyHasAssigne = updatedPlateDetails.every(item => item.operator_name && item.operator_name !== " ");

        // if (anyHasPlateSerialNo || !anyHasAssigne) {
        //     if(anyHasPlateSerialNo && anyHasAssigne) {
        //         setToastMessage("Some plates has plate seriel no");
        //     }
        //     if(!anyHasPlateSerialNo && !anyHasAssigne) {
        //         setToastMessage("Please assign an Operator to generate the Plate QR.");
        //     }
        //     setToastType("error")
        //     setShowToast(true)
        // } else {
        //     setShowGenerateQrModal(true);

        // }

        if (anyHasPlateSerialNo) {
            setToastMessage("Some plates has plate seriel no");
            setToastType("error")
            setShowToast(true)
        } else {
            setShowGenerateQrModal(true);

        }
    }

    const handleDownloadQR = async (slno: string[]) => {
        const filteredPlateDetails = assignedTaskList.filter((item : any) => slno.includes(item.slno));
        const scheduledOperatorIds: any = filteredPlateDetails.map((item: any) => item.id).join(',');

        const allHavePlateSerialNo = filteredPlateDetails.every((item: any) => item.plateSerialNo && item.plateSerialNo !== "");
    
        // Now you can use the `allHavePlateSerialNo` variable as needed
        if (allHavePlateSerialNo) {
            try {
                const response = await scheduleService.getGeneratedProductionPlateQrV2({
                schedule_production_id: scheduledOperatorIds,
                });
                if (response.status && response.statusCode === 200) {
                if (Array.isArray(response.data) && response.data?.length > 0) {
                    setSelectedSlnoLen(response.data?.length);
                    setQrFiles(response.data);
                    setQrModalShow(true);
                } else {
                    // setToastType("success");
                    setToastType("error");
                    setToastMessage("No plates are generated yet.");
                    setShowToast(true);
                }
                } else {
                setToastType("error");
                setToastMessage(response.message);
                setShowToast(true);
                }
            } catch (error) {
                console.error("user response", error);
            }

        } else {
            // alert("Some items are missing plateSerialNo or it's not in the list.");
            setToastMessage("Some items are missing plate serial no");
            setToastType("error")
            setShowToast(true)
        }
    }

    //clear modal on close - QR
    const handleQRFormClose = () => {
        setQrFormData(defaultQrFormData);
        setSelectedRow(defaultFormData);
        setShowGenerateQrModal(false);
        setOpTableData([]);
        setTotalPlateCount(0);
    };

    //handle onchange input QR fields
    const handleQRFieldOnChange = async (e: any) => {
        const { name, value } = e.target;
        console.log("handleFieldOnChange=>{ name, value }", { name, value });

        if (name == "batchId") {
        let lots = await getLotList(value);
        setLotList(lots);
        }
        if (name == "lotId") {
            let selectedLot: any = lotList.filter((item : any) => item.id === value);
            console.log(selectedLot, "selectedLot")
            setTotalPlateCount(selectedLot[0]?.remaining_plates_count)
        }
        setQrFormData((prevFormData: any) => ({
        ...prevFormData,
        [name]: value,
        }));
    };

    //handle add area submit api call
    const handleQRFormSubmit = async (selectedOperators: any[]) => {
        if (_h.isNotEmpty(selectedOperators)) {
        // let i = 0;
        let totalQuantity = 0;
        let request: any = [];
        Object.values(selectedOperators).forEach(async (val, index) => {
            request.push({
            lot_id: qrFormData.lotId,
            quantity: 1,
            master_activity_id: val?.activity_type_id,
            location_id: val.location_id,
            operator_id: val.user_master_id || "",
            schedule_operator_id: val.id,
            });
            totalQuantity = index + 1;
            // const dataToSend = {
            //   lot_id: qrFormData.lotId,
            //   quantity: val.plateCount,
            //   master_activity_id: val?.activity_type_id,
            //   location_id: val.location_id,
            //   operator_id: val.user_master_id,
            //   schedule_operator_id: val.id,
            // };
            // try {
            //   const response = await scheduleService.generatePlateQr(dataToSend);
            //   if (response.status && response.statusCode === 200) {
            //     i++;
            //     if (selectedOperators.length <= i) {
            //       setToastType("success");
            //       setToastMessage(response.message);
            //       getProductionOperators(selectedRow?.id);
            //       setShowToast(true);
            //       // handleFormClose();
            //     }
            //   } else {
            //     setToastType("error");
            //     setToastMessage(response.message);
            //     getProductionScheduleList();
            //     setShowToast(true);
            //     return;
            //   }
            // } catch (error) {
            //   console.error(error);
            //   getProductionScheduleList();
            // }
        });
        const dataToSend = {
            operators: request,
            lot_id: qrFormData.lotId,
            totalQuantity: totalQuantity,
            interface_name: modalName,
        }
        try {
            const response = await scheduleService.generateBulkPlateQrV2(dataToSend);
            if (response.status && response.statusCode === 200) {
            setToastType("success");
            setToastMessage(response.message);
            handleQRFormClose();
            setActionClearAll(true)
            } else {
            setToastType("error");
            setToastMessage(response.message);
            }
            // getProductionOperators(selectedRow?.id);
            listAssignedTask();
            setShowToast(true);
        } catch (error) {
            console.error(error);
            // getProductionScheduleList();
        }
        }
    };

     //handle area validation starts here
    const handleQRFormValidation = (selectedOperators: any[]) => {
        const errors: Errors = {};
        // if (!qrFormData.activity_type_id) {
        //   errors.activity_type_id = "Please select an activity type";
        // }
        if (!qrFormData.batchId) {
        errors.batchId = "Please select a batch";
        }
        if (!qrFormData.lotId) {
        errors.lotId = "Please select a lot";
        }
        // if (!qrFormData.nextSerialNo) {
        //   errors.nextSerialNo = "Please enter next serial No";
        // }
        // if (!qrFormData.plateCount) {
        //   errors.plateCount = "Please enter plate count";
        // }
        if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return;
        }
        // if (!_h.arrayHasData(selectedOperators)) {
        // setToastType("error");
        // setToastMessage("Please select atleast one operator");
        // setShowToast(true);
        // return;
        // }
        setErrors({});
        handleQRFormSubmit(opTableData);
    };

    //handle single and multi QR download button click
    const handleQrDownload = async () => {
        const selectedOperators = opTableData.filter((item: any) =>
        selectedOpSlNo.includes(item.slno)
        );
        handleQRFormValidation(selectedOperators);
    };

    //handle op table pagination
    const handleOpPageChange = (newPage: any, pageSize?: number) => {
        setOpPage(newPage);
        if (pageSize) {
        setOpPageSize(pageSize);
        }
        // getProductionOperators("");
    };

     //handle download qr button
    const handleDownloadBtn = () => {
        const content: any = document.getElementById("innerDiv");
        const pri: any = window.open("", "", "height=600,width=800");
        pri.document.write(content.innerHTML);
        pri.onafterprint = () => {
        pri.close();
        setQrModalShow(false);
        setActionClearAll(true);
        setCellWidth(1);
        };
        pri.print();
    };

    const handleCloseQrModal = () => {
        setQrModalShow(false);
        setCellWidth(1); 
    }

    //table filter section
    const handleFilter = (key: string, value: any) => {
        if (key === "search") {
            setkeyWord(value);
          } else if (key === "activityType") {
            setActivityTypeId(value)
            getMasterProcessList(value)
            getLocationByActivity(value)
        //   } else if (key === "process") {
        //     setProcessId(value)
          } else if (key === "productionSchedule") {
            setProScheduleId(value)
        //   } else if (key === "status") {
        //     setStatus(value)
          } else if (key === "location") {
            setLocation(value)
          } else if (key === "area") {
            setAreaId(value)
          } else if (key === "assignee") {
            setAssignee(value)
          } else if (key === "FromDate") {
            const formattedDate = value ? moment(value).format("YYYY-MM-DD 00:00:00") : "";
            setFromDate(formattedDate)
            setToDate("")
          } else if (key === "ToDate") {
            const formattedDate = value ? moment(value).format("YYYY-MM-DD 23:59:59") : "";
            setToDate(formattedDate)
          }

          setUseEffectRequired(true);
    };

    //create grid for qr section
    const createGrid = () => {
        return (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
            {qrFiles.map((cellIndex: any, index: number) => (
            <div
                key={index}
                style={{
                border: "0.5px solid grey",
                margin: "15px 15px",
                alignItems: "center",
                }}
            >
                <img
                src={baseUrl + "/" + cellIndex?.qr_file_path}
                alt={`Image ${cellIndex?.production_schedule_name}`}
                style={{
                    width: `${cellWidth}cm`,
                    height: `${cellWidth}cm`,
                    pageBreakInside: "avoid",
                }}
                />
                <p
                style={{
                    fontSize: `${cellWidth + 10}px`,
                    fontWeight: "400",
                    margin: "2%",
                    textAlign: "left",
                }}
                >
                {/* Production: <u>{cellIndex?.production_schedule_name}</u>
                <br></br>
                Activity: <u>{cellIndex?.activity_type_name}</u>
                <br></br>
                Process: <u>{cellIndex?.process_name}</u>
                <br></br> */}
                 Activity: {cellIndex?.activity_type_name}
                 <br></br>
                 Location: {cellIndex?.location_name}
                <br></br>
                 Operator: {cellIndex?.user_first_name + " " + cellIndex?.user_last_name}
                <br></br>
                 Batch No: {cellIndex?.batch_no}
                <br></br>
                 Lot Code: {cellIndex?.lot_code}
                <br></br>
                 Serial No: {cellIndex?.serial_number}
                 <br></br>
                 QR Generated Date & Time: {moment(cellIndex?.qr_generated_datetime).format("DD-MM-YYYY - HH:mm:ss")}
                <br></br>
                 Plate Media Type: {cellIndex?.media_type_name}
                {/* Use Within:{" "}
                <u>
                    {moment(
                    cellIndex.start_date_time,
                    "YYYY-MM-DD HH:mm:ss"
                    ).format("DD-MM-YYYY - HH:mm:ss") +
                    " and " +
                    moment(cellIndex.end_date_time, "YYYY-MM-DD HH:mm:ss").format(
                        "DD-MM-YYYY - HH:mm:ss"
                    )}
                </u> */}
                </p>
            </div>
            ))}
        </div>
        );
    };

    useEffect(() => {
        listAssignedTask();
        getBatchList();
        getActivityTypeList();
        getMasterUserList();
        getAreaList();

        setActionClearAll(false)
        setUseEffectRequired(false)

    }, [useEffectRequired])

  return (
    <div>
        <HeaderSection />
        <PageHeader pageHeader={t("generatePlateQr.generatePlateQr")} />
        <CustomToast
            show={showToast}
            onClose={() => setShowToast(false)}
            delay={5000}
            message={toastMessage}
            toastType={toastType}
        />

        <div className="me-3" style={{ marginTop: "10px" }}>
            <div className=" mx-3 pe-2" style={{ height: "45vh" }}>
            <CustomTable
                tableHead="Assigned Task"
                data={assignedTaskList}
                columns={columns}
                // isEditable={permission?.includes("edit_operator_schedule")}
                isEditable={false}
                totalElements={totalElements}
                isActionVisible={true}
                isViewVisible={false}
                // onViewClick={handleViewClick}
                isSeachable={false}
                // addButton={true}
                // addButtonText={t("generatePlateQr.generatePlateQr")}
                // onAddClick={handleGeneratePlateQR}
                onFilterClick={handleFilter}
                // onSortClick={handleSort}
                // isToggleStatus={false}
                // onStatusChange={handleStatusChange}
                isQrDownloadable={false}
                // onEditClick={handleEditClick}
                showQrGenerateButton={false}
                // showBlueBtn={true}
                // blueBtnText={t("generatePlateQr.downloadQr")}
                // onBlueBtnClick={handleDownloadQR}
                showToDateFilter={true}
                showFromDateFilter={true}
                dateType='datetime-local'
                actionCheckOrangeButton={true}
                actionCheckOrangeBtnText={t("generatePlateQr.downloadQr")}
                onActionCheckOrangeButtonClick={handleDownloadQR}
                actionCheckOrangeButton2={true}
                actionCheckOrangeBtnText2={t("generatePlateQr.generatePlateQr")}
                onActionCheckOrangeButtonClick2={handleGeneratePlateQR}
                showFullFilters={true}
                ActivityType={activityType}
                process={processList}
                assignee={usersList}
                clearAll={actionClearAll}
                areaDropList={areaData}
                locationList={locationList}
            />
            </div>
            <div>
            <Pagination
                page={page}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
            />
            </div>
        </div>


        {/*modal for generate QR */}
        <Modal
            className="top-right-modal-md"
            centered={false}
            show={showGenerateQrModal}
            // size='lg'
        >
            <div>
            <div className="modalHead">
                <p className="modalHeadText ms-3 pt-3 p-2">
                {modalName}
                </p>
                <div
                style={{ cursor: "pointer" }}
                onClick={() => handleQRFormClose()}
                className="p-2 mx-3 my-2 "
                >
                <TfiClose fill="#FFFFFF" />
                </div>
            </div>
            <div className="selectWrap-new">
                <div className="form-group my-1 mx-3 px-2">
                <label>
                    {t("scheduleProduction.batch")}
                    <span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <select
                    id="batchId"
                    name="batchId"
                    onChange={handleQRFieldOnChange}
                >
                    <option value="">Select</option>
                    {batchList.map((val) => (
                    <option key={val.id} value={val.id}>
                        {val.name}
                    </option>
                    ))}
                </select>
                {errors.batchId && <p className="errorText">{errors.batchId}</p>}
                </div>
                <div className="form-group my-1 mx-3 px-2">
                <label>
                    {t("scheduleProduction.lot")}
                    <span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <select id="lotId" name="lotId" onChange={handleQRFieldOnChange}>
                    <option value="">Select</option>
                    {lotList.map((val) => (
                    <option key={val.id} value={val.id}>
                        {val.name}
                    </option>
                    ))}
                </select>
                {errors.lotId && <p className="errorText">{errors.lotId}</p>}
                </div>
                {/* <div className="form-group my-1 mx-3 px-2">
                <label>
                    {t("scheduleProduction.nextSerialNo")}
                    <span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <input
                    id="nextSerialNo"
                    name="nextSerialNo"
                    type="text"
                    placeholder={t("scheduleProduction.nextSerialNo")}
                    className="filter-input"
                    onChange={handleQRFieldOnChange}
                />
                {errors.nextSerialNo && (
                    <p className="errorText">{errors.nextSerialNo}</p>
                )}
                </div> */}
                <div className="form-group my-1 mx-3 px-2">
                <label>
                    {t("scheduleProduction.totalPlatesNeeded")}
                </label>
                <br />
                <input
                    id="plateCount"
                    name="plateCount"
                    type="text"
                    placeholder={t("scheduleProduction.totalPlatesNeeded")}
                    className="filter-input"
                    // onChange={handleQRFieldOnChange}
                    disabled={true}
                    value={totalPlateCount}
                />
                {errors.plateCount && (
                    <p className="errorText">{errors.plateCount}</p>
                )}
                </div>
            </div>
            {/* <div className="selectWrap-new">
                <div className="form-group my-1 mx-3 px-2">
                <label>
                    {t("scheduleProduction.activityType")}
                    <span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <select
                    id="activity_type_id"
                    name="activity_type_id"
                    onChange={handleQRFieldOnChange}
                    disabled={true}
                    value={selectedRow?.activity_type_id}
                >
                    <option value="">select</option>
                    {activityType.map((val) => (
                    <option key={val.id} value={val.id}>
                        {val.name}
                    </option>
                    ))}
                </select>
                {errors.activity_type_id && (
                    <p className="errorText">{errors.activity_type_id}</p>
                )}
                </div>
            </div> */}

            <div className="me-3" style={{ marginTop: "10px" }}>
                <div className="mx-3 pe-2">
                <CustomTable
                    tableHead={t("scheduleProduction.productionOperators")}
                    data={opTableData}
                    columns={opColumns}
                    isEditable={false}
                    totalElements={opTableData.length}
                    isActionVisible={false}
                    isViewVisible={false}
                    isSeachable={false}
                    addButton={false}
                    isToggleStatus={false}
                    isQrDownloadable={false}
                    showQrGenerateButton={false}
                    // isQrDownloadable={true}
                    // onEditClick={handleEditClick}
                    // onQrDownloadClick={handleQrDownload}
                    onActionClick={(val) => {
                    console.log("onActionClick=>val", val);
                    setSelectedOpSlNo(val);
                    }}
                />
                </div>
                <div>
                <Pagination
                    page={opPage}
                    totalPages={opTotalPages}
                    handlePageChange={handleOpPageChange}
                />
                </div>
            </div>

            <div className="bottomArea">
                <button
                onClick={() => handleQRFormClose()}
                className="modalCancelBtn"
                >
                {t("buttons.cancel")}
                </button>
                <button
                onClick={() => handleQrDownload()}
                className="squareOrangeBtn3"
                >
                {t("buttons.generatePlateQR")}
                </button>
            </div>
            </div>
        </Modal>

        {/*modal for download QR*/}
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={qrModalShow}
        >
            <Modal.Header closeButton onClick={() => handleCloseQrModal()}>
            <Modal.Title id="contained-modal-title-vcenter">
                {t("buttons.download_qr")}
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <p>Selected {selectedSlnoLen} plate(s) for download QR</p>
            <div className="selectWrap">
                <div className="form-group my-1">
                <label>{t("areaManagement.cell_width")}</label>
                <br />
                <input
                    type="number"
                    id="cellWidth"
                    value={cellWidth}
                    min="1"
                    onChange={(e) => {
                    const value = parseInt(e.target.value);
                    if (value > 0) {
                        setCellWidth(value); // Update state only if value is greater than zero
                    }
                    }}
                />
                </div>
            </div>
            <div
                id="innerDiv"
                hidden
                style={{
                border: "1px solid black",
                padding: "5px",
                margin: "5px",
                overflow: "scroll",
                height: "80vh",
                }}
            >
                {createGrid()}
            </div>
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={() => handleDownloadBtn()}>
                {t("buttons.submit")}
            </Button>
            </Modal.Footer>
        </Modal>

    </div>
  )
}

export default GenerateQrPlate