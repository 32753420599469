

export const columns: TableColumn[] = [
    {
      Header: "tableCommon.sl_no",
      accessor: "slno",
    },
    {
      Header: "scheduleFrequency.tableColumns.activity_type_name",
      accessor: "activity_type_name",
    },
    // {
    //   Header: "scheduleOperator.tableColumns.process_name",
    //   accessor: "process_name",
    // },
    {
      Header: "scheduleProduction.tableColumns.area_name",
      accessor: "area_name",
    },
    {
      Header: "generatePlateQr.tableColumns.location",
      accessor: "location_name",
    },
    {
      Header: "generatePlateQr.tableColumns.dateTime",
      accessor: "start_date_time",
    },
    // {
    //   Header: "scheduleOperator.tableColumns.status",
    //   accessor: "status",
    // },
    {
      Header: "scheduleOperator.tableColumns.assignee",
      accessor: "operator_name",
    },
    {
      Header: "generatePlateQr.tableColumns.plateSerialNo",
      accessor: "plateSerialNo",
    },
  ];

export const opColumns: TableColumn[] = [
  {
    Header: "tableCommon.sl_no",
    accessor: "slno",
  },
  {
    Header: "generatePlateQr.tableColumns.operator",
    accessor: "operator_name",
  },
  {
    Header: "generatePlateQr.tableColumns.activity",
    accessor: "activity_type_name",
  },
  {
    Header: "generatePlateQr.tableColumns.process",
    accessor: "process_name",
  },
  {
    Header: "generatePlateQr.tableColumns.area",
    accessor: "area_name",
  },
  {
    Header: "Location",
    accessor: "location_name",
  },
  {
    Header: "generatePlateQr.tableColumns.dateTime",
    accessor: "start_date_time",
  },
  // {
  //   Header: "scheduleOperator.tableColumns.status",
  //   accessor: "status",
  // },
  // {
  //   Header: "scheduleOperator.tableColumns.assignee",
  //   accessor: "assignee",
  // },
  // {
  //   Header: "generatePlateQr.tableColumns.plateSerialNo",
  //   accessor: "plateSerialNo",
  // },
];

  interface TableColumn {
    Header: string;
    accessor: string;
  }