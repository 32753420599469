

export const columns: TableColumn[] = [
    {
      Header: 'tableCommon.sl_no',
      accessor: 'slno',
    },
    {
      Header: 'areaManagement.tableColumns.area_code',
      accessor: 'area_code',
    },
    {
      Header: 'areaManagement.tableColumns.area_name',
      accessor: 'name',
    },
    {
      Header: 'tableCommon.area_type',
      accessor: 'area_type',
    },
  ];

  //table columns for location management table section 
  export const locationColumns: TableColumn[] = [
    {
      Header: 'tableCommon.sl_no',
      accessor: 'slno',
    },
    {
      Header: 'locationManagement.tableColumns.location_code',
      accessor: 'area_code',
    },
    {
      Header: 'tableCommon.location_name',
      accessor: 'name',
    },
    {
      Header: 'scheduleProduction.tableColumns.area_name',
      accessor: 'area_name',
    },
    {
      Header: 'tableCommon.location_type',
      accessor: 'area_type',
    },
  ];
  
  interface TableColumn {
    Header: string;
    accessor: string;
  }
  