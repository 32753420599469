import React, { useEffect, useState } from "react";
import HeaderSection from "../../common/headerSection";
import PageHeader from "../../common/pageHeader";
import CustomToast from "../../../widgets/toaster";
import CustomTable from "../../../widgets/table";
import { columns } from "./partials/column";
import { columnsByBatch } from './partials/column';
import Pagination from "../../../widgets/pagination";
import { userService as plateRelatedService } from '../../../service/plateServices';
import { BatchService } from "../../../service/plateServices/discard";
import { Button, Modal } from "react-bootstrap";
import { TfiClose } from "react-icons/tfi";
import { useTranslation } from "react-i18next";
import AlertModal from "../../../widgets/alertModal";
import { userService } from "../../../service/userService";




const TestTubeSwabConfiguration = () => {

  const { t } = useTranslation();


  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");

  const [tab, setTab] = useState("1");
  const [testTubeDetails, setTestTubeDetails] = useState<Array<any>>([]);
  const [testTubeBatchDetails, setTestTubeBatchDetails] = useState<Array<any>>([]);
  const [batchTestTubeList, setBatchTestTubeList] = useState([]);

  const [useEffectRequired, setUseEffectRequired] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  // pagination
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);

  const [BatchByPage, setBatchByPage] = useState(1)
  const [BatchByPageSize, setBatchByPageSize] = useState(5)
  const [BatchByTotalPages, setBatchByTotalPages] = useState(0)
  const [BatchByTotalElements, setBatchByTotalElements] = useState(0)

  const [plateStatus, setPlateStatus] = useState<any | null>("");
  const [plateBatches, setPlateBatches] = useState([]);
  const [plateSerielNum, setPlateSerielNums] = useState([]);
  const [keyWord, setkeyWord] = useState("");
  const [closeIconText, setCloseIconText] = useState("");
  const [batchId, setBatchId] = useState("");

  const [userId, setUserId] = useState<number>(0);
  const [showAddUserAlert, setShowAddUserAlert] = useState(false);
  const [showBulkUploadModal, setShowBulkUploadAddModal] = useState(false);
  const [addPlateData, setAddPlateData] = useState({
    batchCode: "",
    serialNumber: "",
    quantity: "",
    manufactureDate: "",
    expiryDate: "",
    plateType: "",
    manufactureDetails: "",
  });
  interface Errors {
    batchCode?: string;
    serialNumber?: string;
    quantity?: string;
    manufactureDate?: string;
    expiryDate?: string;
    plateType?: string;
    manufactureDetails?: string;
  }
  interface NextSerial {
    serial_number?: string;
    next_serial_value?: string;
  }
  const [errors, setErrors] = useState<Errors>({});
  const [testTubeTypes, setTestTubeTypes] = useState([]);
  const [nextSerial, setNextSerial] = useState<NextSerial>({});
  const [sort, setSort] = useState("id");
  const [order, setOrder] = useState("desc");
  const [batchList, setBatchList] = useState([]);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [permission, setPermission] = useState<any>([]);
  const [showButtonWithCloseIcon, setShowButtonWithCloseIcon] = useState(false);
  const [selectedSlnoLen, setSelectedSlnoLen] = useState(0);
  const [selectedSerielNumbers, setSelectedSerielNumbers] = useState<string[]>([]);
  const [modalPlateShow, setModalPlateShow] = useState(false);
  const [cellWidth, setCellWidth] = useState(1);





  //api call for test tube batch listing 
  const getBatchList = async (sort:any , order:any) => {
    try {
      const response = await BatchService.getSwabStickBatchDetails(page, pageSize, sort, order , keyWord)
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              setTotalPages(response?.totalPages)
              setTotalElements(response?.totalElements)
              let rowData: any = response.data.map((element: any, index: number) => {
                return { 
                    id : element.id,
                    slno: index + 1, 
                    batch_number: element.batch_no,
                    manufacturer_details: element.manufacturer_details,
                    plate_type: element.test_tube_size_type_name,
                    quantity: element.swab_count,
                };
              });
              setTestTubeBatchDetails(rowData);
            }
          } catch (error) {
            console.log(error); 
          }
      } else {
        setTestTubeBatchDetails([])
      }
    } catch (error) {
      console.error('Plate response', error);
    }

  }

  const getPermissionByRole = async (role : number) => {
    try {
      const response = await userService.getPermissionByRole(role);
      if(response.status && response.statusCode === 200){
        setPermission(response.data.permissions)
      }
    } catch (error) {
      console.error(error);
    }
  }

  //list for inner test tubes under each batch 
  const listBatchPlateDetails = async (BatchByPage: any, BatchByPageSize: any, sort:any , order:any, batchId: string, keyWord: any) => {
    try {
      const response = await BatchService.getInnerSwabStickDetails(BatchByPage, BatchByPageSize, sort, order, batchId, keyWord);
      if(response.status && response.statusCode === 200){
          if ( Array.isArray(response.data) && response.data?.length > 0) {
            setBatchByTotalPages(response?.totalPages)
            setBatchByTotalElements(response?.totalElements)
            let rowData: any = response.data.map((element: any, index: number) => {
              const role = element.role_master_id === "1" ?
                 "Admin" : element.role_master_id === "2" ?
                 "Operator" : element.role_master_id === "3"? 
                 "Lab Operator": null
                const StatusConstants = {
                  NEW: "1",
                  SWAB_STARTED: "2",
                }; 

                 let testTubeStatus 

                 switch (element.swab_status) {
                  case StatusConstants.NEW:
                      testTubeStatus = "NEW";
                      break;
                  case StatusConstants.SWAB_STARTED:
                    testTubeStatus = "SWAB STARTED";
                      break;
                  default:
                    testTubeStatus = "UNKNOWN STATUS";
                  }
                //  const status = plateRelatedService.getPlateStatus(statusNumber);
                return { 
                    id : element.id,
                    slno: index + 1, 
                    batch_number: element.batch_no,
                    serial_number: element.serial_number,
                    manufacturing_date: element.manufacture_date,
                    expiry_date: element.expiry_date,
                    status: testTubeStatus,
                };
            });
            setBatchTestTubeList(rowData);
          }
      } else {
        setBatchTestTubeList([]);
      }
    } catch (error) {
      return error;
    }
  }

  //on cell click 
  const handleCellClick = (rowData: any) => {
    if(rowData.column.id === "quantity"){
      setShowButtonWithCloseIcon(true)
      setCloseIconText(rowData?.row?.original?.batch_number)
      setBatchId(rowData?.row?.original?.id)
      listBatchPlateDetails(BatchByPage, BatchByPageSize,sort,order, rowData?.row?.original?.id, keyWord)
    }
  }

  //handle table filters
  const handleFilter = (key: string, value: string) => {
    if(key === "batch_code") {
      setkeyWord(value)
    }
    setUseEffectRequired(true)
  }

  //serial number listing for table drop down 
  const listSerialNumber = async () => {
    try {
      const response = await BatchService.SwabStickDropDown();
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any, index: number) => {

                return { 
                    value: element.serial_number,
                    label: element.serial_number ,
                };
              });
              setPlateSerielNums(rowData);
            }
          } catch (error) {
            console.log(error); 
          }
      }
    } catch (error) {
      console.error('Plate response', error);
    }
  }

  //batch list for filter dropdown 
  const listPlateBatch = async () => {
    try {
      const response = await BatchService.getSwabStickBatchFilter();
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any, index: number) => {

                return { 
                    value: element.id,
                    label: element.batch_no ,
                };
              });
              setPlateBatches(rowData);
            }
          } catch (error) {
            console.log(error); 
          }
      }
    } catch (error) {
      console.error('Plate response', error);
    }
  }

  //api call to get the test tube types 
  const getPlateTypeList = async () => {
    try {
      const response = await plateRelatedService.listAllTestTubeTypes();
      console.log(response.data, "data")
      if(response.status && response.statusCode === 200){
        setTestTubeTypes(response.data)
      }
    } catch (error) {
      console.error('user response', error);
    }
  }

  //set the serial number by default in add modal
  const listNextPlateSerial = async () => {
    try {
      const response = await plateRelatedService.listNextSwabStickSerial();
      if(response.status && response.statusCode === 200){
        setNextSerial(response.data)
      }
    } catch (error) {
      console.error('user response', error);
    }
  }

  //handle upload button click 
  const onAddClick = () => {
    getPlateTypeList();
    listNextPlateSerial();
    setShowAddModal(true)
  }

  //clear add plate modal after close 
  const addPlateClose = () => {
    setShowAddModal(false)
    setErrors({})
    setAddPlateData({
      batchCode:"",
      serialNumber: "",
      quantity: "",
      manufactureDate: "",
      expiryDate : "",
      plateType : "",  
      manufactureDetails: ""
    });
    setNextSerial({
      serial_number: "",
      next_serial_value: ""
    })
  }

  //on click on selected incubator list 
  const handleCloseBtnClick = () => {
    setShowButtonWithCloseIcon(false)
  }

  const handleBatchByPageChange = (newPage : any, pageSize?: number) => {
    setBatchByPage(newPage);
    if(pageSize) {
      setBatchByPageSize(pageSize)
      listBatchPlateDetails(newPage, pageSize,sort,order, batchId, keyWord)
    } else {
      listBatchPlateDetails(newPage, BatchByPageSize,sort,order, batchId, keyWord)
    }
  }

  //handle PlateListFilter filters
  const handlePlateListFilter = (key: string, value: any) => {
    if(key === "search") {
      setkeyWord(value)
      if (value === "") {
        listBatchPlateDetails(BatchByPage, BatchByPageSize,sort,order, batchId, value)
      } else {
        listBatchPlateDetails(BatchByPage, BatchByPageSize,sort,order, "", value)
      }
    }
  }


  //handle table pagination 
  const handlePageChange = (newPage : any, pageSize?: number) => {
    setPage(newPage);
    if(pageSize) {
      setPageSize(pageSize)
    }
    setUseEffectRequired(true)
  };

  //onchage add plate event 
  const handleAddPlateChange = (e : any) => {
    const { name, value } = e.target;

    if (name === "quantity") {
      if (!/^\d*$/.test(value)) {
        return;
      }
    }

    setAddPlateData((prevFormData : any) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  //handle plate alert 
  const handlePlateAlert = () => {
    const errors: Errors = {};
    if (!addPlateData.batchCode) {
      errors.batchCode = "Please Enter Batch code";
    }
    // if (!addPlateData.plateType) {
    //   errors.plateType = "Please select Plate Type";
    // }
    if (!addPlateData.quantity) {
      errors.quantity = "Please Enter Quantity";
    }
    if (!addPlateData.manufactureDate) {
      errors.manufactureDate = "Please Enter Manufacture Date";
    }
    if (!addPlateData.expiryDate) {
      errors.expiryDate = "Please Enter Expiry Date";
    }
    if (!addPlateData. manufactureDetails) {
      errors.manufactureDetails = "Please Enter Manufacturer Details";
    }
    
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    setShowAddUserAlert(true)
    setErrors({});
  }

  //api call on add test tube event
  const handleAddPlateSubmit = async () => {
    const dataToSend = {
      user_id : userId,
      batch_number: addPlateData.batchCode,
      manufacture_date: addPlateData.manufactureDate,
      manufacturer_details: addPlateData.manufactureDetails,
      // test_tube_type: addPlateData.plateType,
      expiry_date: addPlateData.expiryDate,
      range_from: nextSerial.next_serial_value,
      swab_count: addPlateData.quantity,
      interface_name : "Swab Stick"
    }
    
    try {
      const response = await plateRelatedService.createSwabStick(dataToSend);
      if (response.status && response.statusCode === 200) {
        setToastType("success")
        setToastMessage(response.message)
        // getPlateList();
        getBatchList(sort ,order)
        addPlateClose()
      } else {
        setToastType("error")
        setToastMessage(response.message)
      }
      setShowAddUserAlert(false)
      setShowToast(true)
      // addPlateClose()
      setUseEffectRequired(true)
    } catch (error) {
      console.error(error);
    }
  }

  const createPlateGrid = () => {
    return (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {selectedSerielNumbers.map((cellIndex : any) => (
          <div key={cellIndex} style={{ display: "flex", border: '0.5px solid grey', margin: "15px 15px", "alignItems" : "center"}}>
            <p style={{"fontSize": `${cellWidth + 10}px`, "fontWeight":"800", "margin": "3px",}} >{cellIndex?.serial_number}</p>
          </div>
        ))}
      </div>
)
  }


  // handle qr section 
  const handleQrDownload = async (slno: string[], type: string) => {
    const filteredUserDetails = batchTestTubeList.filter((item: any) => slno.includes(item.slno));
    setSelectedSlnoLen(slno.length)
    if (type === "single") {
      // setShowGenerateQrAlert(true)
    } else if (type === "multi") {
      setModalPlateShow(true)
      setSelectedSerielNumbers(filteredUserDetails)
    }
  }

  const handlePlateDownloadBtn = () => {
    const content : any = document.getElementById('innerDiv');
    const pri: any = window.open('', '', 'height=600,width=800');
    pri.document.write(content.innerHTML);
    pri.onafterprint = () => {
      pri.close();
      setModalPlateShow(false)
    };
    pri.print();
  }

  const checkSpecialChar =(e : any)=>{
    if(!/[0-9a-zA-Z]/.test(e.key)){
     e.preventDefault();
    }
   };


  useEffect (() => {
    var storedData = localStorage.getItem('userDetails');
  
    if(storedData) {
      var storedObject = JSON.parse(storedData);
      setUserId(storedObject[0].id);
      getPermissionByRole(storedObject[0].role_master_id)
    }
    getBatchList(sort , order)
    listPlateBatch()
    listSerialNumber()
    setUseEffectRequired(false)
  }, [useEffectRequired])


  return (
    <div>
      <HeaderSection />
      <PageHeader pageHeader="Swab Stick Inventory" />
      <CustomToast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={5000}
        message={toastMessage}
        toastType={toastType}
      />
      <div className=" mx-3 pe-2" style={{ height: "45vh" }}>
      {!showButtonWithCloseIcon ?  (
          <CustomTable
            tableHead="Swab Stick Inventory"
            data={testTubeBatchDetails}
            columns={columns}
            isEditable={false}
            totalElements={totalElements}
            isActionVisible={false}
            isViewVisible={false}
            onAddClick={onAddClick}
            addButtonText="Bulk Upload"
            addButton={permission?.includes("add_test_tube")}
            showBlueBtn={false}
            blueBtnText="Plate Sampling"
            plateSerialNumber={plateSerielNum}
            plateBatch={plateBatches}
            isSeachable={false}
            showPlateStatus={false}
            onFilterClick={handleFilter}
            onCellClick={handleCellClick}
          />
      ) : (
        <div className='mx-3 pe-3' style={{"height":"45vh"}}>
          <CustomTable
            tableHead='Swab Stick Details' 
            data={batchTestTubeList} 
            columns={columnsByBatch} 
            isEditable={false} 
            isActionVisible={true} 
            isViewVisible={false} 
            totalElements={BatchByTotalElements}
            isSeachable={false}
            isToggleStatus={false}  
            plateSerialNumber={plateSerielNum}    
            onFilterClick={handlePlateListFilter}
            buttonWithCloseIcon={showButtonWithCloseIcon}
            buttonWithCloseIconText={closeIconText}
            onCloseBtnClick={() => handleCloseBtnClick()}
            isQrDownloadable={true}
            onQrDownloadClick={handleQrDownload}
        />
      </div>
      )}
    </div>

    <div>
            {!showButtonWithCloseIcon ? (
              <Pagination
                page={page}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
              />
            ) : (
              <Pagination
                page={BatchByPage}
                totalPages={BatchByTotalPages}
                handlePageChange={handleBatchByPageChange}
              />
            )}
          </div>

          <Modal
              className="top-right-modal"
              centered={false}
              show={showAddModal}
          >
            <div>
                <div className="modalHead">
                <p className="modalHeadText ms-3 pt-3 p-2">{t('plateInventory.bulk')}</p>
                <div onClick={() => addPlateClose()} className="p-2 mx-3 my-2 ">
                  <TfiClose fill="#FFFFFF" />
                </div>
              </div>
              <div>
                  <div className="selectWrap">
                  <div className="form-group my-1">
                    <label htmlFor="">{t('plateInventory.batch_code')} <span style={{ color: 'red' }}>*</span></label>
                    <br />
                    <input 
                       id="batchCode"
                       value={addPlateData.batchCode}
                       name="batchCode"
                       type='text'
                       placeholder='Batch Code'
                       className="placeholder-style"
                       onChange={handleAddPlateChange}
                       onKeyDown={(e)=>checkSpecialChar(e)}
                    />
                    {errors.batchCode && <p className="errorText">{errors.batchCode}</p>}
                  </div>

                  <div className="form-group my-1">
                    <label>{t('plateInventory.starting_serial')} <span style={{ color: 'red' }}>*</span></label>
                    <br />
                    <input 
                       id="serialNumber"
                       value={nextSerial?.serial_number || ''}
                       name="serialNumber"
                       type='text'
                       placeholder='Starting Serial Number'
                       className="placeholder-style"
                       onChange={handleAddPlateChange} 
                       disabled
                       style={{backgroundColor: "lavenderblush", fontWeight : "600"}}
                    />
                    {errors.serialNumber && <p className="errorText">{errors.serialNumber}</p>}
      
                  </div>
                </div>
                <div className="selectWrap">
                  <div className="form-group my-1">
                    <label htmlFor="">{t('plateInventory.quantity')} <span style={{ color: 'red' }}>*</span></label>
                    <br />
                    <input 
                      id="quantity"
                      value={addPlateData.quantity}
                      name="quantity"
                      type='number'
                       placeholder='Quantity'
                       className="placeholder-style"
                      onChange={handleAddPlateChange} 
                      min={0}
                    />
                    {errors.quantity && <p className="errorText">{errors.quantity}</p>}
                  </div>

                  <div className="form-group my-1">
                    <label htmlFor="">{t('plateInventory.manufactureDate')} <span style={{ color: 'red' }}>*</span></label>
                    <br />
                    <input 
                       id="manufactureDate"
                       value={addPlateData.manufactureDate}
                       name="manufactureDate"
                       type='date'
                       placeholder='Manufacturing Date'
                       className="placeholder-style"
                       onChange={handleAddPlateChange} 
                       max={new Date().toISOString().split("T")[0]}
                      //  className="custom-time-input"
                      onKeyDown={(e) => {
                        // Allow navigation keys (e.g., Tab, Arrow keys) and Enter to open the date picker
                        const allowedKeys = [
                          "Tab",
                          "Enter",
                          "Escape",
                        ];
                        if (!allowedKeys.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onPaste={(e) => e.preventDefault()}
                    />
                    {errors.manufactureDate && <p className="errorText">{errors.manufactureDate}</p>}
                  </div>
                </div>
                <div className="selectWrap">
                <div className="form-group my-1">
                    <label htmlFor="">{t('plateInventory.expiryDate')} <span style={{ color: 'red' }}>*</span></label>
                    <br />
                    <input 
                       id="expiryDate"
                       value={addPlateData.expiryDate}
                       name="expiryDate"
                       type='date'
                       placeholder='Expiry Date'
                       onChange={handleAddPlateChange} 
                       min={addPlateData.manufactureDate}
                       disabled={!addPlateData.manufactureDate}
                      //  className="custom-time-input"
                      className="placeholder-style"
                      onKeyDown={(e) => {
                        // Allow navigation keys (e.g., Tab, Arrow keys) and Enter to open the date picker
                        const allowedKeys = [
                          "Tab",
                          "Enter",
                          "Escape",
                        ];
                        if (!allowedKeys.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onPaste={(e) => e.preventDefault()}
                    />
                    {errors.expiryDate && <p className="errorText">{errors.expiryDate}</p>}
                  </div>

                  {/* <div className="form-group my-1">
                    <label htmlFor="">Test Tube Type</label>
                    <br />
                   
                    <select
                      id="plateType"
                      value={addPlateData.plateType}
                      name="plateType"
                      onChange={handleAddPlateChange} 
                    >
                      <option value="">select</option>
                      {testTubeTypes.map((obj : any, index) => (
                          <option key={index} value={obj?.id}>
                            {obj?.tube_type}
                          </option>
                      ))}
                    </select>
                    {errors.plateType && <p className="errorText">{errors.plateType}</p>}
                  </div>                */}
                  <div className="form-group my-1">
                    <label htmlFor="">{t('plateInventory.manufacture_details')} <span style={{ color: 'red' }}>*</span></label>
                    <br />
                    <input 
                      id="manufactureDetails"
                      value={addPlateData.manufactureDetails}
                      name="manufactureDetails"
                      type='text'
                       placeholder='Manufacturer Details'
                       className="placeholder-style"
                      onChange={handleAddPlateChange} 
                      onKeyDown={(e)=>checkSpecialChar(e)}
                    />
                    {errors.manufactureDetails && <p className="errorText">{errors.manufactureDetails}</p>}
                  </div>
                </div>
  
                <div className="selectWrap-email">
                  
                </div>
                <div className="form-group my-1"></div>
              </div>
              <div className="bottomArea">
            <button onClick={() => addPlateClose()} className="modalCancelBtn">
            {t('buttons.cancel')}
            </button>
            <button onClick={() => handlePlateAlert()} className="squareOrangeBtn3">{t('buttons.submit')}</button>
          </div>
            </div>
          </Modal>

          <Modal
              // size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={modalPlateShow}
            >
              <Modal.Header closeButton onClick={() => setModalPlateShow(false)}>
                <Modal.Title id="contained-modal-title-vcenter">
                {t('plateInventory.download')}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Selected {selectedSlnoLen} stick(s) to download QR</p>
                <div className="selectWrap">
                 
                  <div className="form-group my-1">
                    <label>{t('plateInventory.cell_width')}</label>
                    <br />
                    <input 
                       type="number"
                       id="cellWidth"
                       value={cellWidth}
                       min="1"
                       onChange={(e) => {
                        const value = parseInt(e.target.value);
                        if (value > 0) {
                          setCellWidth(value); // Update state only if value is greater than zero
                        }
                      }}
                    />
      
                  </div>
                </div>
                <div id="innerDiv" hidden style={{ border: '1px solid black', padding: '5px', margin:"5px", overflow: "scroll",height: "80vh"}}>
                  {createPlateGrid()}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={() => handlePlateDownloadBtn()}>{t('buttons.submit')}</Button>
              </Modal.Footer>
          </Modal>

        {/*Alert for Bulk upload */}
        <AlertModal 
          show={showAddUserAlert}
          onHide={() => setShowAddUserAlert(false)}
          title='Alert'
          message={`Add Bulk Upload`}
          onConfirm={handleAddPlateSubmit}
        />

    </div>
  );
};

export default TestTubeSwabConfiguration;
