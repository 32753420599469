export const activeSamplingColumns: TableColumn[] = [
    {
        Header: 'Sl.No',
        accessor: 'slno',
      },
      {
        Header: 'Area Name',
        accessor: 'area',
      },
      {
        Header: 'Location',
        accessor: 'location',
      },
      {
        Header: 'Serial Number',
        accessor: 'serial_number',
      },
      {
        Header: 'Batch Code',
        accessor: 'batch_number',
      },
      {
        Header: 'Lot Code',
        accessor: 'lot_code',
      },
      {
        Header: 'Equipment Code',
        accessor: 'area_code',
      },
      {
        Header: 'Sampling Started By',
        accessor: 'sampling_started_by',
      },
      {
        Header: 'Sampling Started Date',
        accessor: 'start_date_time',
      },
      {
        Header: 'Sampling Duration',
        accessor: 'sampling_duration',
      },
      {
        Header: 'First Incubation Start By',
        accessor: 'incubation_started_by',
      },
      {
        Header: 'First Incubation Start Date',
        accessor: 'incubation_start_date',
      },
      {
        Header: 'First Incubation End By',
        accessor: 'incubation_end_by',
      },
      {
        Header: 'First Incubation End Date',
        accessor: 'incubation_end_date',
      },
      {
        Header: 'First Incubation Duration',
        accessor: 'incubation_duration',
      },
      {
        Header: 'Second Incubation Start By',
        accessor: 'incubation2_started_by',
      },
      {
        Header: 'Second Incubation Start Date',
        accessor: 'incubation2_start_date',
      },
      {
        Header: 'Second Incubation End By',
        accessor: 'incubation2_end_by',
      },
      {
        Header: 'Second Incubation End Date',
        accessor: 'incubation2_end_date',
      },
      {
        Header: 'Second Incubation Duration',
        accessor: 'incubation2_duration',
      },
      {
        Header: 'Observer 1 Name',
        accessor: 'observer1_name',
      },
      {
        Header: 'Count 1',
        accessor: 'count1',
      },
      {
        Header: 'Observer 2 Name',
        accessor: 'observer2_name',
      },
      {
        Header: 'Count 2',
        accessor: 'count2',
      },
      {
        Header: 'Observer 3 Name',
        accessor: 'observer3_name',
      },
      {
        Header: 'Count 3',
        accessor: 'count3',
      },
      {
        Header: 'Approved Id',
        accessor: 'approved_id',
      },
  ];

export const passiveSamplingColumns: TableColumn[] = [
  {
      Header: 'Sl.No',
      accessor: 'slno',
    },
    {
      Header: 'Area Name',
      accessor: 'area',
    },
    {
      Header: 'Location',
      accessor: 'location',
    },
    {
      Header: 'Serial Number',
      accessor: 'serial_number',
    },
    {
      Header: 'Batch Code',
      accessor: 'batch_number',
    },
    {
      Header: 'Lot Code',
      accessor: 'lot_code',
    },
    {
      Header: 'Location Code',
      accessor: 'area_code',
    },
    {
      Header: 'Sampling Started Date',
      accessor: 'start_date_time',
    },
    {
      Header: 'Sampling Duration',
      accessor: 'sampling_duration',
    },
    {
      Header: 'First Incubation Start By',
      accessor: 'incubation_started_by',
    },
    {
      Header: ' First Incubation Start Date',
      accessor: 'incubation_start_date',
    },
    {
      Header: 'First Incubation End By',
      accessor: 'incubation_end_by',
    },
    {
      Header: 'First Incubation End Date',
      accessor: 'incubation_end_date',
    },
    {
      Header: 'First Incubation Duration',
      accessor: 'incubation_duration',
    },
    {
      Header: 'Second Incubation Start By',
      accessor: 'incubation2_started_by',
    },
    {
      Header: 'Second Incubation Start Date',
      accessor: 'incubation2_start_date',
    },
    {
      Header: 'Second Incubation End By',
      accessor: 'incubation2_end_by',
    },
    {
      Header: 'Second Incubation End Date',
      accessor: 'incubation2_end_date',
    },
    {
      Header: 'Second Incubation Duration',
      accessor: 'incubation2_duration',
    },
    {
      Header: 'Observer 1 Name',
      accessor: 'observer1_name',
    },
    {
      Header: 'Count 1',
      accessor: 'count1',
    },
    {
      Header: 'Observer 2 Name',
      accessor: 'observer2_name',
    },
    {
      Header: 'Count 2',
      accessor: 'count2',
    },
    {
      Header: 'Observer 3 Name',
      accessor: 'observer3_name',
    },
    {
      Header: 'Count 3',
      accessor: 'count3',
    },
    {
      Header: 'Approved Id',
      accessor: 'approved_id',
    },
  
];

export const personnelMonitoringColumns: TableColumn[] = [
  {
      Header: 'Sl.No',
      accessor: 'slno',
    },
    {
      Header: 'Operator ID',
      accessor: 'operator_id',
    },
    {
      Header: 'Plate Serial Number',
      accessor: 'plate_id',
    },
    {
      Header: 'Batch Code',
      accessor: 'batch_number',
    },
    {
      Header: 'Lot Code',
      accessor: 'lot_code',
    },
    {
      Header: 'Person Name',
      accessor: 'person_name',
    },
    {
      Header: 'Area Name',
      accessor: 'location',
    },
    {
      Header: 'Monitoring Type',
      accessor: 'monitoring_type',
    },
    {
      Header: 'Sampling Start Date',
      accessor: 'sampling_start_date',
    },
    {
      Header: 'First Incubation Start By',
      accessor: 'incubation_started_by',
    },
    {
      Header: ' First Incubation Start Date',
      accessor: 'incubation_start_date',
    },
    {
      Header: 'First Incubation End By',
      accessor: 'incubation_end_by',
    },
    {
      Header: 'First Incubation End Date',
      accessor: 'incubation_end_date',
    },
    {
      Header: 'First Incubation Duration',
      accessor: 'incubation_duration',
    },
    {
      Header: 'Second Incubation Start By',
      accessor: 'incubation2_started_by',
    },
    {
      Header: 'Second Incubation Start Date',
      accessor: 'incubation2_start_date',
    },
    {
      Header: 'Second Incubation End By',
      accessor: 'incubation2_end_by',
    },
    {
      Header: 'Second Incubation End Date',
      accessor: 'incubation2_end_date',
    },
    {
      Header: 'Second Incubation Duration',
      accessor: 'incubation2_duration',
    },
    {
      Header: 'Observer 1 Name',
      accessor: 'observer1_name',
    },
    {
      Header: 'Count 1',
      accessor: 'count1',
    },
    {
      Header: 'Observer 2 Name',
      accessor: 'observer2_name',
    },
    {
      Header: 'Count 2',
      accessor: 'count2',
    },
    {
      Header: 'Observer 3 Name',
      accessor: 'observer3_name',
    },
    {
      Header: 'Count 3',
      accessor: 'count3',
    },
    {
      Header: 'Approved Id',
      accessor: 'approved_id',
    },
  
];

export const surfaceMonitoringColumns: TableColumn[] = [
  {
      Header: 'Sl.No',
      accessor: 'slno',
    },
    {
      Header: 'Area Name',
      accessor: 'area',
    },
    {
      Header: 'Location',
      accessor: 'location',
    },
    {
      Header: 'Serial Number',
      accessor: 'plate_number',
    },
    {
      Header: 'Batch Code',
      accessor: 'batch_number',
    },
    {
      Header: 'tableCommon.lot_code',
      accessor: 'lot_code',
    },
    {
      Header: 'Test Tube Serial',
      accessor: 'test_tube_number',
    },
    {
      Header: 'Sampling Done By',
      accessor: 'first_name',
    },
    {
      Header: 'Date Time',
      accessor: 'start_date_time',
    },
    {
      Header: 'First Incubation Start By',
      accessor: 'incubation_started_by',
    },
    {
      Header: 'First Incubation Start Date',
      accessor: 'incubation_start_date',
    },
    {
      Header: 'First Incubation End By',
      accessor: 'incubation_end_by',
    },
    {
      Header: 'First Incubation End Date',
      accessor: 'incubation_end_date',
    },
    {
      Header: 'First Incubation Duration',
      accessor: 'incubation_duration',
    },
    {
      Header: 'Second Incubation Start By',
      accessor: 'incubation2_started_by',
    },
    {
      Header: 'Second Incubation Start Date',
      accessor: 'incubation2_start_date',
    },
    {
      Header: 'Second Incubation End By',
      accessor: 'incubation2_end_by',
    },
    {
      Header: 'Second Incubation End Date',
      accessor: 'incubation2_end_date',
    },
    {
      Header: 'Second Incubation Duration',
      accessor: 'incubation2_duration',
    },
    {
      Header: 'Observer 1 Name',
      accessor: 'observer1_name',
    },
    {
      Header: 'Count 1',
      accessor: 'count1',
    },
    {
      Header: 'Observer 2 Name',
      accessor: 'observer2_name',
    },
    {
      Header: 'Count 2',
      accessor: 'count2',
    },
    {
      Header: 'Observer 3 Name',
      accessor: 'observer3_name',
    },
    {
      Header: 'Count 3',
      accessor: 'count3',
    },
    {
      Header: 'Approved Id',
      accessor: 'approved_id',
    },   
  
];

export const equipmentConfigurationColumns: TableColumn[] = [
  {
      Header: 'Sl.No',
      accessor: 'slno',
    },
    {
      Header: 'Equipment Name',
      accessor: 'name',
    },
    {
      Header: 'Equipment Code',
      accessor: 'equipment_code',
    },
    {
      Header: 'Calibaration Date',
      accessor: 'startDate',
    },
    {
      Header: 'Calibaration Due Date',
      accessor: 'endDate',
    },
    {
      Header: 'Min Temperature',
      accessor: 'min_temp',
    },
    {
      Header: 'Max Temperature',
      accessor: 'max_temp',
    },
  
];

export const plateActivityColumns: TableColumn[] = [
  {
      Header: 'Sl.No',
      accessor: 'slno',
    },
    {
      Header: 'Date',
      accessor: 'logDate',
    },
    {
      Header: 'User',
      accessor: 'name',
    },
    {
      Header: 'Action',
      accessor: 'action',
    },
    {
      Header: 'Location',
      accessor: 'location',
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
    {
      Header: 'Reasons',
      accessor: 'reason',
    },
  
];


export const surfaceMonitoringReport: TableColumn[] = [
  {
      Header: 'Sl.No',
      accessor: 'slno',
    },
    {
      Header: 'Area Name',
      accessor: 'area',
    },
    {
      Header: 'Location',
      accessor: 'location',
    },
    {
      Header: 'Serial Number',
      accessor: 'plate_number',
    },
    {
      Header: 'Batch Code',
      accessor: 'batch_number',
    },
    {
      Header: 'tableCommon.lot_code',
      accessor: 'lot_code',
    },
    {
      Header: 'Sampling Done By',
      accessor: 'first_name',
    },
    {
      Header: 'Date Time',
      accessor: 'start_date_time',
    },
    {
      Header: 'Incubation Start By',
      accessor: 'incubation_started_by',
    },
    {
      Header: 'Incubation Start Date',
      accessor: 'incubation_start_date',
    },
    {
      Header: 'Incubation End By',
      accessor: 'incubation_end_by',
    },
    {
      Header: 'Incubation End Date',
      accessor: 'incubation_end_date',
    },
    {
      Header: 'Incubation Duration',
      accessor: 'incubation_duration',
    },
    {
      Header: 'Second Incubation Start By',
      accessor: 'incubation2_started_by',
    },
    {
      Header: 'Second Incubation Start Date',
      accessor: 'incubation2_start_date',
    },
    {
      Header: 'Second Incubation End By',
      accessor: 'incubation2_end_by',
    },
    {
      Header: 'Second Incubation End Date',
      accessor: 'incubation2_end_date',
    },
    {
      Header: 'Second Incubation Duration',
      accessor: 'incubation2_duration',
    },
    {
      Header: 'Observer 1 Name',
      accessor: 'observer1_name',
    },
    {
      Header: 'Count 1',
      accessor: 'count1',
    },
    {
      Header: 'Observer 2 Name',
      accessor: 'observer2_name',
    },
    {
      Header: 'Count 2',
      accessor: 'count2',
    },
    {
      Header: 'Observer 3 Name',
      accessor: 'observer3_name',
    },
    {
      Header: 'Count 3',
      accessor: 'count3',
    },
    {
      Header: 'Approved Id',
      accessor: 'approved_id',
    },
  
];

export const batchSummaryReport: TableColumn[] = [
  {
      Header: "Sl.No",
      accessor: "slno",
  },
  {
    Header: "tableCommon.batch_code",
    accessor: "batch_code",
  },
  {
    Header: "QR Generated On",
    accessor: "qr_generated_datetime",
  },
  {
    Header: "Plate Type",
    accessor: "plate_size_type_name",
  },
  {
    Header: "Media Type",
    accessor: "media_type_name",
  },
  {
    Header: "Expiry Date",
    accessor: "expiry_date",
  },
  {
    Header: "tableCommon.seriel_number",
    accessor: "serial_number",
  },
  {
    Header: "Lot Code",
    accessor: "lot_code",
  },
  {
    Header: "Activity Name",
    accessor: "activity",
  },
  {
      Header: "Area Name",
      accessor: "area_name",
  },
  {
      Header: "Location Name",
      accessor: "location",
  },
  {
    Header: "Equipment Name",
    accessor: "exposure_equipment_name",
  },
  {
    Header: "Started Date/Time",
    accessor: "sampling_started_date",
  },
  {
      Header: "Started By",
      accessor: "sampling_started_by",
  },
  {
      Header: "Ended Date/Time",
      accessor: "sampling_ended_date",
  },
  {
      Header: "Ended By",
      accessor: "sampling_ended_by",
  },
  {
      Header: "Duration",
      accessor: "sampling_duration",
  },
  // {
  //     Header: "Test Tube Serial No",
  //     accessor: "test_tube_serial_no",
  // },
  // {
  //     Header: "Equipment Code",
  //     accessor: "equipment_code",
  // },
  {
    Header: "Personnel Monitored Location",
    accessor: "monitored_location",
  },
  {
      Header: "Personnel Monitored Assigned User",
      accessor: "monitored_user",
  },
  {
    Header: "1st Incubator Name",
    accessor: "first_incubatior_name",
  },
  {
    Header: "1st Incubation Started Date/Time",
    accessor: "first_incubation_start_date",
  },
  {
      Header: "1st Incubation Started  By",
      accessor: "first_incubation_start_by",
  },
  {
    Header: "1st Incubation End Date/Time",
    accessor: "first_incubation_end_date",
  },
  {
      Header: "1st IncubationEnded By",
      accessor: "first_incubation_end_by",
  },
  {
      Header: "1st Incubation Duration",
      accessor: "first_incubation_duration",
  },
  {
    Header: "2nd Incubator Name",
    accessor: "second_incubatior_name",
  },
  {
    Header: "2nd Incubation Started Date/Time",
    accessor: "second_incubation_start_date",
  },
  {
      Header: "2nd Incubation Started By",
      accessor: "second_incubation_start_by",
  },
  {
    Header: "2nd Incubation End Date/Time",
    accessor: "second_incubation_end_date",
  },
  {
      Header: "2nd Incubation End By",
      accessor: "second_incubation_end_by",
  },
  {
      Header: "2nd Incubation Duration",
      accessor: "second_incubation_duration",
  },
  {
    Header: "Lab Area Name / Code",
    accessor: "lab_1_area_name",
  },
  {
      Header: "1st Observer Name",
      accessor: "observer1_name",
  },
  {
      Header: "1st Observer Count",
      accessor: "count1",
  },
  {
      Header: "2nd Observer Name",
      accessor: "observer2_name",
  },
  {
      Header: "2nd Observer Count",
      accessor: "count2",
  },
  {
      Header: "3rd Observer Name",
      accessor: "observer3_name",
  },
  {
      Header: "3rd Observer Count",
      accessor: "count3",
  },
  {
    Header: "Approved By",
    accessor: "approved_user_name",
  },
  {
    Header: "Approved Date / Time",
    accessor: "approved_date",
  },
  {
    Header: "Approver Remarks",
    accessor: "approver_remarks",
  },
  {
    Header: "Flora Conducted By",
    accessor: "flora_conducted_by",
  },
  {
    Header: "Flora Conducted Date / Time",
    accessor: "flora_conducted_date",
  },
  {
      Header: "Colony Type",
      accessor: "colony_type",
  },
  {
      Header: "Organism Type",
      accessor: "organism_type",
  },
  {
      Header: "Genus",
      accessor: "genus",
  },
  {
      Header: "Species",
      accessor: "species",
  },
  {
      Header: "Strain",
      accessor: "strain",
  },
  {
    Header: "Autoclave Name",
    accessor: "autoclave_name",
  },
  {
    Header: "Autoclave Started Date/Time",
    accessor: "autoclave_start_date",
  },
  {
      Header: "Autoclave Started By",
      accessor: "autoclave_start_by",
  },
  {
    Header: "Autoclave End Date/Time",
    accessor: "autoclave_end_date",
  },
  {
      Header: "Autoclave End By",
      accessor: "autoclave_end_by",
  },
  {
      Header: "Autoclave Duration",
      accessor: "autoclave_duration",
  },
  // {
  //     Header: "Plate Status",
  //     accessor: "plate_status",
  // },
  // {
  //     Header: "Approved ID",
  //     accessor: "approve_id",
  // },
];



  interface TableColumn {
    Header: string;
    accessor: string;
  }