export const generateCSVData = (): string[][] => {
    return [
        ['Batch Code*','Plate Count*','Plate Type*','Plate Media Type*','Expiry Date*','Manufacturing date*','Manufacturer Details*','Batch GRN*','Lot Size*','Visual Inspection Flag*'],
        ['csvtest1', '30', '90mm','DEY','3/11/2025', '18-08-2021', 'tr3', '907', '10', 'yes'],
        ['cst2', '11', '90mm','DEY','3/11/2025', '3/11/2023', 'tr3', '909', '10', 'yes'],
        ['cs3', '13', '90mm','DEY','3/11/2025', '3/11/2021', 'tr3', '900', '10', 'yes']
    ];
};


// ['BATCH NUMBER', 'SERIAL NUMBER PREFIX', 'RANGE_FROM', 'PLATE COUNT', 'PLATE_TYPE', 'EXPIRY DATE', 'MANUFACTURING DATE', 'MANUFACTURER DETAILS'],
// ['B10001', 'MPATS PL', '277', '5', '90mm', '13-09-2024', '01-12-2023', 'Sample'],
// ['B10001', 'MPATS PL', '278', '5', '90mm', '13-09-2024', '01-12-2023', 'Sample']

