import React, { useEffect, useState } from "react";
import HeaderSection from "../../../common/headerSection";
import PageHeader from "../../../common/pageHeader";
import { Link, useNavigate, useParams } from "react-router-dom";
import Chart from "../partials/chart";
import { FaCheck, FaTimes } from "react-icons/fa";
import CustomTable from "../../../../widgets/table";
import {
  ScheduleSummary,
  ExceptionSummary,
} from "./Violation/columns";
import DownloadPdf from "../../../../widgets/downloadPdf";
import { useTranslation } from "react-i18next";
import ActiveAirSamplingReport from "../../reportpdf/summaryReport/activeSamplingReport";
import EquipmentConfiguration from "../../reportpdf/summaryReport/equipementConfiguration";
import PassiveAIrSampingReport from "../../reportpdf/summaryReport/passiveSamplingReport";
import PersonnelMonitoring from "../../reportpdf/summaryReport/personnelMonitoring";
import PlateActivityreport from "../../reportpdf/summaryReport/plateActivityLog";
import SurfaceMonitoring from "../../reportpdf/summaryReport/surfaceMonitoring";
import DeviationReport from "../../reportpdf/deviation";
import VisualInspection from "../../reportpdf/visualInspectionReport";
import { trendAnalytics } from "../../../../service/trendAnalytics";
import { FiFilter } from "react-icons/fi";
import Pagination from "../../../../widgets/pagination";
import { userService } from "../../../../service/plateServices";
import {userService as userRoleServive} from '../../../../service/userService';
import PlateReconcilationTreeTable from "../../plateReconcilation_V2";
import SurfaceMonitoringReport from "../../reportpdf/summaryReport/surfaceMonitoringReport_v2";
import BatchSummaryReport from "../../reportpdf/summaryReport/batchSummaryReport";


const SummaryReport: React.FC = () => {
  const { bulletId } = useParams<{ bulletId: string }>();
  const { t } = useTranslation();

  const advFiltersHide: any = ["active_sampling", "batch_summary_report", "passive_sampling", "monitoring_report", "surface_monitoring", "configuration_report", "inspection", "incident_deviation", "surface_monitoring_report", "schedule", "area", "location","operator"]

  const [selectedArea, setSelectedArea] = useState<string>("");
  const [filterData, setFilterData] = useState({
    area: "",
    location: "",
    activityType: "",
    shift: "",
    organismType: "",
    organismStrain: "",
    organismGenus: "",
    organismSpecies: "",
    operatorType: "",
    exceptionReason: "",
    fromDate: "",
    toDate: "",
    serialNumber: "",
    batchCode: "",
  });
  const [areaDropDown, setareaDropDown] = useState<Array<any>>([]);
  const [locationDropDown, setlocationDropDown] = useState<Array<any>>([]);
  const [shift, setShift] = useState<Array<any>>([]);
  const [organismType , setOrganismType] = useState<Array<any>>([]);  
  const [genus , setGenus] = useState<Array<any>>([]); 
  const [species , setSpecies] = useState<Array<any>>([]);  
  const [strain , setStrain] = useState<Array<any>>([]); 
  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false);
  const [showAdvanceFilterBtn, setShowAdvanceFilterBtn] = useState(true);
  const [activityTypes, setActivityTypes] = useState<Array<any>>([]);
  const [summaryDetails, setSummaryDetails] = useState<Array<any>>([]);
  const [exceptionReasons, setExceptionReasons] = useState<Array<any>>([]);
  const [userData , setUserData] = useState<Array<any>>([]);

  // pagination
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);

  const [useEffectRequired, setUseEffectRequired] = useState(false);
  const [plateBatches, setPlateBatches] = useState([]);
  const [plateSerielNum, setPlateSerielNums] = useState([]);

  const [pageSummary, setPageSummary] = useState(1);
  const [pageSizeSummary, setPageSizeSummary] = useState(5);
  const [totalPagesSummary, setTotalPagesSummary] = useState(0);
  const [totalElementsSummary, setTotalElementsSummary] = useState(0);
  const [sort, setSort] = useState("id");
  const [order, setOrder] = useState("desc");

  const bulletIdToAreaMap: { [key: string]: string } = {
    area: "Area",
    location: "Location",
    operator: "Operator",
    schedule: "Schedule Summary",
    discrepancy: "Discrepency Summary",
    exception: "Exception Summary",
    active_sampling: "Active Sampling Report",
    batch_summary_report : "Batch Summary Report",
    passive_sampling: "Passive Sampling Report",
    monitoring_report: "Personnel Monitoring Report",
    surface_monitoring: "Surface Monitoring - Swab Collection Report",
    surface_monitoring_report: "Surface Monitoring",
    configuration_report: "Equipments",
    inspection: "Visual Inspection",
    incident_deviation: "Incident Deviation",
    plate_report: "Plate Activity Report",   
  };

  //api call for table summary 
  const getPlateList = async () => {
    try {
      const response = await trendAnalytics.getExceptionSummaryData(filterData, page, pageSize, sort, order);
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              setTotalPages(response?.totalPages)
              setTotalElements(response?.totalElements)
              let rowData: any = response.data.map((element: any, index: number) => {

                return { 
                    id : element.id,
                    slno: index + 1, 
                    batch_code: element.batch_number,
                    serial_number: element.serial_number,
                    reason: element.reason,
                    date: element.exception_requested_datetime,
                    area_name: element.area_name,
                    location_name:element.location_name,
                    operator_name: `${element.exception_requested_user_firstname} ${element.exception_requested_user_lastname}`,
                    operator_id: element.exception_requested_by,
                    status: element.status_label
                };
              });
              setSummaryDetails(rowData);
            }
          } catch (error) {
            console.log(error); 
          }
      } else {
        setSummaryDetails([])
        setTotalPages(0)
        setTotalElements(0)

      }
    } catch (error) {
      console.error('Plate response', error);
    }
  };

  const getAreaLocationOperatorSummary = async () => {
    try {
      const response = await trendAnalytics.getAreaLocationOperatorSummary(filterData, bulletId, pageSummary, pageSizeSummary,sort, order);
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              setTotalPagesSummary(response?.totalPages)
              setTotalElementsSummary(response?.totalElements)
              let rowData: any = response.data.map((element: any, index: number) => {

                return { 
                    slno: index + 1, 
                    area_name: element.area_name,
                    operator: element.operator_name,
                    location: element.location_name,
                    date: element.date,
                    activity_type: element.activity_type,
                    total_plates: element.total_plates,
                    colony_count: element.colony_count,
                    excursion_count : element.excursions
                };
              });
              setSummaryDetails(rowData);
            }
          } catch (error) {
            console.log(error); 
          }
      } else {
        setSummaryDetails([])
        setTotalPagesSummary(0)
        setTotalElementsSummary(0)

      }
    } catch (error) {
      console.error('Plate response', error);
    }
  };

  const getScheduleSummary = async () => {
    try {
      const response = await trendAnalytics.getScheduleSummary(filterData, page, pageSize, sort, order);
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {      
              setTotalPages(response?.totalPages)
              setTotalElements(response?.totalElements)
              let rowData: any = response.data.map((element: any, index: number) => {

                return { 
                    slno: index + 1, 
                    schedule_name: element.production_schedule_name,
                    activity_type: element.process_name,
                    area_name: element.area_name,
                    location_name: element.location_name,
                    duration: element.duration_h_m_s,
                    start_date_time: element.scheduled_start_date_time,
                    end_date_time : element.scheduled_end_date_time,
                    actual_start_time: element.actual_start_date_time,
                    actual_end_time : element.actual_end_date_time
                };
              });
              setSummaryDetails(rowData);
            }
          } catch (error) {
            console.log(error); 
          }
      } else {
        setSummaryDetails([])
      }
    } catch (error) {
      console.error('Plate response', error);
    }
  };

  useEffect(() => {
    if (bulletId) {

      if (advFiltersHide.includes(bulletId)) {
          setShowAdvanceFilterBtn(false)
      }

      listAreaType();
      getExceptionReasons();
      listShiftOptions();
      listTypeOptions();
      listActivityTypes();
      listPlateBatch();
      listSerialNumber();
      getOperatorList();
      if (bulletId === "schedule") {
        getScheduleSummary();
      } else if (
        bulletId === "exception" || 
        bulletId === "area" || 
        bulletId === "location" || 
        bulletId === "operator"
      ) {
        if (bulletId === "exception") {
            getPlateList();
        } else {
            getAreaLocationOperatorSummary();
        }
      }

      const selectedArea = bulletIdToAreaMap[bulletId];
            if (selectedArea) {
                setSelectedArea(selectedArea);
            } else {
            setSelectedArea("");
        }
  }
    setUseEffectRequired(false)
  }, [bulletId, useEffectRequired]);

  const handleSubmit = async ()=> {
    setUseEffectRequired(true);
  }

  //handle table pagination
  const handlePageChange = (newPage: any, pageSize?: number) => {
    setPage(newPage);
    if (pageSize) {
      setPageSize(pageSize);
    }
    setUseEffectRequired(true);
  };

  const handlePageChangeSummary = (newPage: any, pageSize?: number) => {
    setPageSummary(newPage);
    if (pageSize) {
      setPageSizeSummary(pageSize);
    }
    setUseEffectRequired(true);
  };

  const violationTrends = ["area", "location", "operator"];
  const violationSummarys = ["schedule", "discrepancy", "exception"];
  const reports = [
    "active_sampling",
    "passive_sampling",
    "monitoring_report",
    "surface_monitoring",
    "configuration_report",
    "inspection",
    "incident_deviation",
    "plate_report",
    "surface_monitoring_report",
    "batch_summary_report"
  ];
  if (bulletId === undefined) {
    return null;
  }
  const trimmedBulletId = bulletId.trim();
  const isInViolationTrends = violationTrends.includes(trimmedBulletId);
  const isInViolationSummarys = violationSummarys.includes(trimmedBulletId);
  const isReportSummary = reports.includes(trimmedBulletId);


  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFilterData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if(name === "fromDate" && filterData.toDate < value) {
      setFilterData(prevState => ({
        ...prevState,
        ["toDate"]: ""
      }));
    }

    if (name === "area") {
      listLocationType(value);
    }
    if(name === "organismType"){
      handleOrganismTypeChange(value); 
    }
    if(name === "organismGenus"){
      handleGenusTypeChange(value); 
    }
    if(name === "organismSpecies"){
      handleSpeciesTypeChange(value); 
    }
  };

  const formatDateTime = (dateString: string) => {
    const date = new Date(dateString);
    const formattedDate =
        `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-` +
        `${date.getDate().toString().padStart(2, '0')} ` +
        `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;

    return formattedDate;
};


  const columns: TableColumn[] = [
    bulletId === "area" ? {
      Header: "Area",
      accessor: "area_name",
    } : undefined,
    (bulletId === "area" || bulletId === "location") ? {
      Header: "Location",
      accessor: "location",
    } : undefined,
    {
      Header: "Operator",
      accessor: "operator",
    },
    {
      Header: "Date",
      accessor: "date",
    },
    {
      Header: "Activity Type",
      accessor: "activity_type",
    },
    {
      Header: "Total Plates",
      accessor: "total_plates",
    },
    {
      Header: "Colony Count",
      accessor: "colony_count",
    },
    {
      Header: "Excursion Count",
      accessor: "excursion_count",
    },
  ].filter((column): column is TableColumn => !!column);

  interface TableColumn {
    Header: string;
    accessor: string;
  }

   //list area types dropdown 
   const listAreaType = async () => {
    try {
      const response = await trendAnalytics.listAreaType();
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any, index: number) => {
  
                return { 
                    value: element.id,
                    label: element.name,
                    type: element.type,
                };
              });
              setareaDropDown(rowData);

            }
          } catch (error) {
            console.log(error); 
          }
      }
    } catch (error) {
      console.error('Plate response', error);
    }
  }

  //list exceptionReasons dropdown 
  const getExceptionReasons = async () => {
    try {
      const response = await userService.getExceptionReasons();
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any, index: number) => {
                if(element.status === "active"){
                  return { 
                      id: element.id ,
                      reason: element.reason ,
                      status: element.status ,
                  };
                }});
              setExceptionReasons(rowData);
            }
          } catch (error) {
            console.log(error); 
          }
      }
    } catch (error) {
      console.error('Plate response', error);
    }
  }

   // list plate batch dropdown
  const listPlateBatch = async () => {
    try {
      const response = await userService.listPlateBatch();
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any, index: number) => {

                return { 
                    value: element.batch_number,
                    label: element.batch_number ,
                };
              });
              setPlateBatches(rowData);
            }
          } catch (error) {
            console.log(error); 
          }
      }
    } catch (error) {
      console.error('Plate response', error);
    }
}

 // list serial number dropdown
const listSerialNumber = async () => {
  try {
    const response = await userService.listSerialNumber();
    if(response.status && response.statusCode === 200){
      try {
          if ( Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map((element: any, index: number) => {

              return { 
                  value: element.serial_number,
                  label: element.serial_number ,
              };
            });
            setPlateSerielNums(rowData);
          }
        } catch (error) {
          console.log(error); 
        }
    }
  } catch (error) {
    console.error('Plate response', error);
  }
}

 // list operators dropdown
 const getOperatorList = async () => {
  try {
    // const userRole = parseInt(addFilterData.role, 10);  
    const response = await userRoleServive.getUserNameList("");
    if(response.status && response.statusCode === 200){
      try {
          if ( Array.isArray(response.data) && response.data?.length > 0) {
            // setTotalPages(response?.totalPages)
            // setTotalElements(response?.totalElements)
            let rowData: any = response.data.map((element: any, index: number) => {

              return { 
                  slno: index + 1, 
                  id: element.id,
                  name: `${element.first_name} ${element.last_name}`,
              };
            });
            setUserData(rowData);
          }
        } catch (error) {
          console.log(error);
        }
    } else {
      setUserData([]);
    }
  } catch (error) {
    console.error(error);
  }
};

  // list location types dropdown
  const listLocationType = async (parent_area: string) => {
    try {
      const response = await trendAnalytics.listLocationType(parent_area);
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any, index: number) => {
  
                return { 
                    value: element.id,
                    label: element.name,
                    type: element.type,
                };
              });
              setlocationDropDown(rowData);

            }
          } catch (error) {
            console.log(error); 
          }
      }
    } catch (error) {
      console.error('Plate response', error);
    }
  }

   //list shift dropdown 
   const listShiftOptions = async () => {
    try {
      const response = await trendAnalytics.listShiftOptions();
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any, index: number) => {
  
                return { 
                    value: element.id,
                    label: element.name,
                };
              });
              setShift(rowData);

            }
          } catch (error) {
            console.log(error); 
          }
      }
    } catch (error) {
      console.error('Plate response', error);
    }
  }

   //api call to organism type
   const listTypeOptions = async () => {
    try {
      const response = await trendAnalytics.listTypeOptions();  
      if (response.status && response.statusCode === 200) {
        const rowData = response.data.map((element:any, index:any) => ({
          sl_no: index + 1,
          id: element.id,
          organism_type: element.organism_type
        }));
        setOrganismType(rowData);
      } else {
        setOrganismType([]);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  //api call to get genus
  const handleOrganismTypeChange = async (selectedTypeId: string) => {
    try {
      const response = await trendAnalytics.listGenusOptions(selectedTypeId);
      if (response.status && response.statusCode === 200) {
        const rowData = response.data.map((element:any, index:any) => ({
          sl_no: index + 1,
          id: element.id,
          genus_name: element.genus_name
        }));
        setGenus(rowData);
      } else {
        setGenus([]);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  //api call to get species
  const handleGenusTypeChange = async (selectedTypeId: string) => {
    try {
      const response = await trendAnalytics.listSpeciesOptions(selectedTypeId);
      if (response.status && response.statusCode === 200) {
        const rowData = response.data.map((element:any, index:any) => ({
          sl_no: index + 1,
          id: element.id,
          name: element.name
        }));
        setSpecies(rowData);
      } else {
        setSpecies([]);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  //api call to get strain
  const handleSpeciesTypeChange = async (selectedTypeId: string) => {
    try {
      const response = await trendAnalytics.listStrainOptions(selectedTypeId);
      if (response.status && response.statusCode === 200) {
        const rowData = response.data.map((element:any, index:any) => ({
          sl_no: index + 1,
          id: element.id,
          name: element.name
        }));
        setStrain(rowData);
      } else {
        setStrain([]);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  //list activity types
  const listActivityTypes = async () => {
    try {
      const response = await trendAnalytics.listActivityTypes();
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any, index: number) => {
  
                return { 
                    label: element.name,
                    value: element.id,
                 
                };
              });
              setActivityTypes(rowData);

            }
          } catch (error) {
            console.log(error); 
          }
      }
    } catch (error) {
      console.error('Plate response', error);
    }
  }

  const handleCancel = () => {
    setFilterData({
      area: "",
      location: "",
      activityType: "",
      shift: "",
      organismType: "",
      organismStrain: "",
      organismGenus: "",
      organismSpecies: "",
      operatorType: "",
      exceptionReason: "",
      fromDate: "",
      toDate: "",
      serialNumber: "",
      batchCode: "",
    })
    setUseEffectRequired(true);
  }

  const handleSort = (key: string, value: boolean) => {
    setSort(key)
    if (value) {
      setOrder("asc")
    } else {
      setOrder("desc")
    }
    setUseEffectRequired(true)
  }

  return (
    <div>
      <HeaderSection />
      <PageHeader pageHeader={t("dashboard.summaryReport")} />
      <nav aria-label="breadcrumb" className="breadcrumb-container row">
        <div className="col-10 d-flex">
          <ol className="breadcrumb">
            <li className="breadcrumb-item dashboard-hover">
              <Link to="/analytics-dashboard">
                {" "}
                {t("dashboard.analyticalDashboard")}
              </Link>
            </li>
            <li className="breadcrumb-item dashboard-hover" aria-current="page">
              <Link to="/inner-landing/Summary">{t("common.summary")}</Link>
            </li>
            <li
              className="breadcrumb-item active selectedArea"
              aria-current="page"
            >
              {selectedArea}
            </li>
          </ol>
        </div>
          <div className="col-2 d-flex">
            {showAdvanceFilterBtn && (
              <div>
                  <button onClick={() => setShowAdvanceFilter(!showAdvanceFilter)} className="filterBtn">{t('buttons.filter')} <FiFilter /></button>
              </div>
            )}
            <DownloadPdf
              divId="dashWrap"
              reportHead={t("dashboard.summaryReport")}
            />
          </div>
      </nav>

      <div id="dashWrap">

      {!isReportSummary && (
        <div>

          <div className="d-flex justify-content-around mt-3">
            {/* Area filter */}
            {bulletId !== "operator" && (
            <div
              className="select-container"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <label htmlFor="" style={{ color: "black", fontSize: "12px" }}>
                {t("common.select_area")}:
              </label>
              <select
                className="areaTypeSelect"
                name="area"
                value={filterData.area}
                onChange={(e) => handleInputChange(e)}
              >
                <option value="">Select</option>
                  {areaDropDown?.map((type : any) => (
                    <option key={type.value} value={type.value}>
                      {type.label}
                    </option>
                  ))}
              </select>
            </div>
            )}

            {/* Location filter */}
            {bulletId !== "operator" && (
            <div
              className="select-container"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <label htmlFor="" style={{ color: "black", fontSize: "12px" }}>
                {t("common.select_location")}:
              </label>
              <select
                className="areaTypeSelect"
                name="location"
                value={filterData.location}
                onChange={(e) => handleInputChange(e)}
              >
                <option value="">Select</option>
                  {locationDropDown?.map((type : any) => (
                    <option key={type.value} value={type.value}>
                    {type.label}
                  </option>
                  ))}
              </select>
            </div>
            )}

            {/* Activity Type filter */}
            {bulletId !== "exception" && (
            <div
              className="select-container"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <label htmlFor="" style={{ color: "black", fontSize: "12px" }}>
                {t("common.select_process")}:
              </label>
              <select
                className="areaTypeSelect"
                name="activityType"
                value={filterData.activityType}
                onChange={(e) => handleInputChange(e)}
              >
                <option value="">Select</option>
                {activityTypes?.map((type) => (
                    <option key={type.value} value={type.value}>
                      {type.label}
                    </option>
                ))}
              </select>
            </div>
            )}

       
              <div
              className="select-container"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <label htmlFor="" style={{ color: "black", fontSize: "12px" }}>
                Select Operator:
              </label>
              <select
                className="areaTypeSelect"
                name="operatorType"
                value={filterData.operatorType}
                onChange={(e) => handleInputChange(e)}
              >
                <option value="">Select</option>
                {userData?.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.name}
                    </option>
                ))}
              </select>
            </div>
    

        
            {/* Exception Reason */}
            {bulletId === "exception" && (
            <div
              className="select-container"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <label htmlFor="" style={{ color: "black", fontSize: "12px" }}>
                    Exception Reason</label> 
               <div>
              <select 
                className='areaTypeSelect '
                name="exceptionReason"
                value={filterData.exceptionReason} 
                onChange={(e) => handleInputChange(e)} 
                >
                  <option value={0}>Exception Reason</option>
                  {exceptionReasons?.map((obj, index) => (
                      <option key={index} value={obj?.id}>
                        {obj?.reason}
                      </option>
                  ))}
              </select>
              </div>
              </div>
            )}

            {/* From Date */}
            <div
              className="select-container analyticFilterDate"
              style={{ display: "flex", flexDirection: "column"}}
            >
              <label htmlFor="" style={{ color: "black", fontSize: "12px" }}>
                {t("common.from_date")}:
              </label>
              <input 
                type="datetime-local" 
                value={formatDateTime(filterData.fromDate)}
                name="fromDate"

                // id="selectedDate"
                // className="form-control"
                // value={formatDateTime(formData.fromDate)}
                // name="fromDate"
                // type="datetime-local"
                onChange={(e) => handleInputChange(e)} 
                onKeyDown={(e) => {
                  const allowedKeys = [
                    "Tab",
                    "Enter",
                    "Escape",
                  ];
                  if (!allowedKeys.includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                onPaste={(e) => e.preventDefault()}
              />
            </div>

            {/* To Date */}
            <div
              className="select-container analyticFilterDate"
              style={{
                display: "flex",
                flexDirection: "column"
              }}
            >
              <label htmlFor="" style={{ color: "black", fontSize: "12px" }}>
                {t("common.to_date")}:
              </label>
              <input 
                type="datetime-local" 
                value={formatDateTime(filterData.toDate)}
                min={formatDateTime(filterData.fromDate)}
                name="toDate"
                onChange={(e) => handleInputChange(e)} 
                onKeyDown={(e) => {
                  const allowedKeys = [
                    "Tab",
                    "Enter",
                    "Escape",
                  ];
                  if (!allowedKeys.includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                onPaste={(e) => e.preventDefault()}
                />
            </div>
          </div>

          {/* <div hidden={(!showAdvanceFilter && bulletId === "exception")}> */}
          {showAdvanceFilter && bulletId != "exception" && (
          <div className="d-flex justify-content-around mt-3" >

            {/* Shift filter */}
            {/* {bulletId != "exception" && (
            <div
              className="select-container"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <label htmlFor="" style={{ color: "black", fontSize: "12px" }}>
                {t("common.select_shift")}:
              </label>
              <select
                className="areaTypeSelect"
                name="shift"
                value={filterData.shift}
                onChange={(e) => handleInputChange(e)}
              >
                <option value="">Select</option>
                {shift?.map((type: any) => (
                  <option key={type.value} value={type.value}>
                    {type.label}
                  </option>
                ))} 
              </select>
            </div>
            )} */}

            {/* Organism Type filter */}
            <div
              className="select-container"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <label htmlFor="" style={{ color: "black", fontSize: "12px" }}>
                {t("common.select_organism_type")}:
              </label>
              <select
                className="areaTypeSelect"
                name="organismType"
                value={filterData.organismType}
                onChange={(e) => handleInputChange(e)}
              >
                <option value="">Select</option>
                {organismType?.map((type : any) => (
                  <option key={type.id} value={type.id}>
                    {type.organism_type}
                  </option>
                ))} 
              </select>
            </div>

            {/* Organism Genus filter */}
            <div
              className="select-container"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <label htmlFor="" style={{ color: "black", fontSize: "12px" }}>
                {t("common.select_organism_genus")}:
              </label>
              <select
                className="areaTypeSelect"
                name="organismGenus"
                value={filterData.organismGenus}
                onChange={(e) => handleInputChange(e)}
              >
                <option value="">Select</option>
                {genus?.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.genus_name}
                  </option>
                ))}
              </select>
            </div>

            {/* Organism Species filter */}
            <div
              className="select-container"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <label htmlFor="" style={{ color: "black", fontSize: "12px" }}>
                {t("common.select_organism_species")}:
              </label>
              <select
                className="areaTypeSelect"
                name="organismSpecies"
                value={filterData.organismSpecies}
                onChange={(e) => handleInputChange(e)}
              >
                <option value="">Select</option>
                {species?.map((type: any) => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </select>
            </div>

            {/* Organism Strain filter */}
            <div
              className="select-container ms-2 ps-1"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <label htmlFor="" style={{ color: "black", fontSize: "12px" }}>
                {t("common.select_organism_strain")}:
              </label>
              <select
                className="areaTypeSelect"
                name="organismStrain"
                value={filterData.organismStrain}
                onChange={(e) => handleInputChange(e)}
              >
                <option value="">Select</option>
                {strain?.map((type: any) => (
                  <option key={type.id} value={type.id}>
                  {type.name}
                </option>
                ))}
              </select>
            </div>
            
            <div className="select-container analyticFilterDate" style={{ display: "flex", flexDirection: "column" }}></div>
            <div className="select-container analyticFilterDate" style={{ display: "flex", flexDirection: "column" }}></div>
            <div className="select-container" style={{ display: "flex", flexDirection: "column" }}></div>
            <div className="select-container" style={{ display: "flex", flexDirection: "column" }}></div>
            <div className="select-container" style={{ display: "flex", flexDirection: "column" }}></div>
            <div className="select-container" style={{ display: "flex", flexDirection: "column" }}></div>
            <div className="select-container" style={{ display: "flex", flexDirection: "column" }}></div>
            <div className="select-container" style={{ display: "flex", flexDirection: "column" }}></div>
          </div>
          )}
          {/* </div> */}

          {showAdvanceFilter && bulletId == "exception" && (
            <div className="d-flex justify-content-start mt-3 mx-4" >
              {/* serial filter */}
            <div
              className="select-container"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <label htmlFor="" style={{ color: "black", fontSize: "12px" }}>
                Seriel Number:
              </label>
              <select
                className="areaTypeSelect"
                name="serialNumber"
                value={filterData.serialNumber}
                onChange={(e) => handleInputChange(e)}
              >
                <option value="">Select</option>
                {plateSerielNum?.map((type : any) => (
                  <option key={type.id} value={type.value}>
                    {type.label}
                  </option>
                ))} 
              </select>
            </div>

            {/* Batch filter */}
            <div
              className="select-container"
              style={{ display: "flex", flexDirection: "column", marginLeft : "4%" }}
            >
              <label htmlFor="" style={{ color: "black", fontSize: "12px" }}>
                Batch Code:
              </label>
              <select
                className="areaTypeSelect"
                name="batchCode"
                value={filterData.batchCode}
                onChange={(e) => handleInputChange(e)}
              >
                <option value="">Select</option>
                {plateBatches?.map((type : any) => (
                  <option key={type.id} value={type.value}>
                    {type.label}
                  </option>
                ))} 
              </select>
            </div>


            </div>
          )}

          <div className="mt-3 mx-2 d-flex justify-content-end">
            <button
              className="analyticalFilterClose"
              title="Click here to Reset Filter"
              onClick={() => handleCancel()}
            >
              {t("buttons.cancel")}
            </button>
            <button
              className="analyticalFilterApply"
              title="Click here to Apply Filter"
              onClick={() => handleSubmit()}
            > 
              {t("buttons.apply")}
            </button>
          </div>

          <div
            className="row mx-2"
            id="dashWrap"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {isInViolationTrends && (
              <div className="col-12">
                <div className="">
                  <CustomTable
                    columns={columns}
                    data={summaryDetails}
                    isActionVisible={false}
                    isViewVisible={false}
                    totalElements={totalElementsSummary}
                    tableHead={`${t("common.summary")} - ${selectedArea}`}
                    onSortClick={handleSort}
                  />
                </div>
                <div>
                  <Pagination
                    page={pageSummary}
                    totalPages={totalPagesSummary}
                    handlePageChange={handlePageChangeSummary}
                  />
                </div>
              </div>
            )}
            {isInViolationSummarys && (
              <div className="col-12">
                <div>
                  <CustomTable
                    columns={bulletId === "schedule" ? ScheduleSummary : ExceptionSummary}
                    data={summaryDetails}
                    isActionVisible={false}
                    isViewVisible={false}
                    totalElements={totalElements}
                    tableHead={selectedArea}
                    onSortClick={handleSort}
                  />
                </div>
                <div>
                  <Pagination
                    page={page}
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {bulletId === "active_sampling" && (
        <ActiveAirSamplingReport ishide={true} />
      )}
      {bulletId === "batch_summary_report" && (
        <BatchSummaryReport ishide={true} />
      )}
      {bulletId === "configuration_report" && (
        <EquipmentConfiguration ishide={true} />
      )}
      {bulletId === "passive_sampling" && (
        <PassiveAIrSampingReport ishide={true} />
      )}
      {bulletId === "monitoring_report" && (
        <PersonnelMonitoring ishide={true} />
      )}
      {bulletId === "plate_report" && <PlateReconcilationTreeTable ishide={true} showFilter={showAdvanceFilter}/>}
      {bulletId === "surface_monitoring" && <SurfaceMonitoring ishide={true} />}
      {bulletId === "inspection" && <VisualInspection ishide={true} />}
      {bulletId === "incident_deviation" && <DeviationReport ishide={true} />}
      {bulletId === "surface_monitoring_report" && <SurfaceMonitoringReport ishide={true} />}

      </div>
    </div>
  );
};

export default SummaryReport;
